import moment from "moment";
import { InputAdornment } from "@mui/material";

import { JSW } from "constants";
import { categoryType } from "constants";

export const validateNull = (value) => {
  return value ? value : "-";
};

export const appendRupeeIcon = (value) => {
  return value ? `₹${value}` : "₹0";
};

export const getFixedValue = (data, digit = 3) => {
  if (data) {
    const value = data.toString();
    const index = value.indexOf(".");
    const newValue = index >= 0 ? value.substr(0, index + digit) : value;
    return newValue;
  }
};

// Round decimal number
export const decimalRoundOff = (num, decimalPlaces = 2) => {
  const factor = Math.pow(10, decimalPlaces);
  const roundedNum = Math.round(num * factor) / factor;
  return roundedNum;
};

export const dateConvertor = (str) => {
  return str ? new Date(str).toLocaleDateString() : "-";
};

export const timeConvertor = (str) => {
  const date = new Date(str);
  if (!str && !date) return "";
  return new Intl.DateTimeFormat("en-IN", { timeStyle: "medium" }).format(date);
};

export const fetchTimeWithoutConversion = (str) => {
  if (!str) return "";
  const date = new Date(str);

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const timeString = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

  return timeString;
};

export const findAttribute = (obj, attributeKey) => {
  const list = Object.keys(obj);
  let result = "";
  for (let i = 0; i < list.length; i++) {
    if (typeof obj[list[i]] === "object") {
      findAttribute(obj[list[i]], attributeKey);
    } else if (list[i] === attributeKey) {
      result = obj[list[i]];
      break;
    }
  }
  return result;
};

export const moneyFormat = (number, decimal = 2) => {
  return number
    ? new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        minimumFractionDigits: decimal,
      }).format(number)
    : appendRupeeIcon("0.00");
};

export const sentenceCase = (str) => {
  if (!str || str.length < 2) return validateNull();
  return str.charAt(0).toUpperCase() + str.slice(1)?.toLowerCase();
};
export const pascalCase = (str) => {
  if (!str) return "";
  if (str?.toLowerCase().includes(JSW)) return str;
  str = str.toLowerCase();
  str = str.split(" ");
  // eslint-disable-next-line array-callback-return
  str.map((ele, i) => {
    ele = ele.charAt(0).toUpperCase() + ele.slice(1);
    str[i] = ele;
  });
  return str.join(" ");
};

export const getPaymentStatusColor = (status) => {
  let color = "";
  if (status === "Pending") {
    color = "red";
  } else if (status === "Full") {
    color = "green";
  } else {
    color = "orange";
  }
  return color;
};

export const removeTimeOffset = (date) => {
  if (date) {
    const formattedDate = date.toString().includes("Z")
      ? date
      : new Date(`${date}Z`).toISOString();
    return formattedDate;
  }
};

export const offsetDays = (offset = 10) => {
  const today = new Date();
  today.setDate(today.getDate() - offset);

  return `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
};

export const getAttributes = (attr) => {
  if (!attr) return;
  const {
    "Leg length": LegLength,
    Thickness,
    "Thickness (mm)": Thickness1,
    Diameter,
    "Width (mm)": Width1,
    Width: Width2,
    Length,
    "Spangle type": SpangleType,
    "Coating grade": CoatingGrade,
    Colour,
    TMT_form,
    "Box size": BoxSize,
    "Bag size": BagSize,
    "Web depth": WebDepth,
    "Flange width": FlangeWidth,
    "Sectional weight": SectionalWeight,
    "Cut length": CutLength,
    Height,
    Depth,
    "Nominal Bore": nominalBore,
    Series,
    "Outside Diameter": outsideDiameter,
    "End Condition": endCondition,
    Form,
    "Nominal Diameter": nominalDiameter,
  } = attr;
  const serialObj = new Map();
  serialObj.set("NominalBore", nominalBore);
  serialObj.set("NominalDiameter", nominalDiameter);
  serialObj.set("Series", Series);
  serialObj.set("OutsideDiameter", outsideDiameter);
  serialObj.set("leglength", LegLength);
  serialObj.set("thickness", Thickness);
  serialObj.set("thickness1", Thickness1);
  serialObj.set("dia", Diameter);
  serialObj.set("Depth", Depth);
  serialObj.set("width1", Width1);
  serialObj.set("width2", Width2);
  serialObj.set("Height", Height);
  serialObj.set("length", Length);
  serialObj.set("SpangleType", SpangleType);
  serialObj.set("CoatingGrade", CoatingGrade ?? attr?.["Coating grade (AZ)"]);
  serialObj.set("Colour", Colour);
  serialObj.set("TMT_form", TMT_form);
  serialObj.set("BoxSize", BoxSize);
  serialObj.set("BagSize", BagSize);
  serialObj.set("WebDepth", WebDepth);
  serialObj.set("FlangeWidth", FlangeWidth);
  serialObj.set("SectionalWeight", SectionalWeight);
  serialObj.set("CutLength", CutLength);
  serialObj.set("EndCondition", endCondition);
  serialObj.set("Form", Form);

  let result = "";
  for (const [, value] of serialObj) {
    if (value) {
      result += "-" + value;
    }
  }
  return result.length ? result : "";
};

// Convert date from mm/dd/yyyy to dd/mm/yyyy
export const formatDate = (value) => {
  if (value) {
    const dateObj = new Date(value);
    const year = dateObj.getFullYear();
    let month = dateObj.getMonth() + 1;
    let date = dateObj.getDate();
    if (date < 10) date = `0${date}`;
    if (month < 10) month = `0${month}`;
    return `${date}/${month}/${year}`;
  } else {
    return "";
  }
};

export const getFixedWords = (value, length) => {
  if (value) {
    let charCount = value?.replace(/\s/g, "").length;
    if (charCount > length) return false;
    return true;
  } else {
    return true;
  }
};

export const decodeBase64 = (value) =>
  Buffer.from(value.toString())?.toString("base64") || "";

export const encodeBase64 = (value) =>
  Buffer(value.toString(), "base64")?.toString() || "";

export const removeCurrencyFormat = (value) => {
  const amount = value?.split("₹")[1];
  const centAmount = amount.replace(/,/g, "");

  return Number(centAmount) ?? 0;
};

export const downloadCsv = (data, fileName) => {
  try {
    const blob = new Blob([data], { type: "text/csv" });

    // Creating an object for downloading url
    const url = window.URL.createObjectURL(blob);

    // Creating an anchor(a) tag of HTML
    const a = document.createElement("a");

    // Passing the blob downloading url
    a.setAttribute("href", url);

    // Setting the anchor tag attribute for downloading
    // and passing the download file name
    a.setAttribute("download", `${fileName}.csv`);

    // Performing a download with click
    a.click();
  } catch (ex) {
    console.log(`Exception : ${ex}`);
  }
};

/**
 * @description Get date with time 00:00:00
 * @returns return date with time 00:00:00
 */
export const getStartDate = (date) => {
  return moment(date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
};

/**
 * @description Get Date with time 23:59:59
 * @returns return date with time 23:59:59
 */
export const getEndDate = (date) => {
  return moment(date).set({
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 59,
  });
};

/**
 * @description Get substring to desired limit
 * @returns returns substring followed by "..."
 */

export const getSubstring = (word, limit) => {
  const substring =
    word?.length > limit ? `${word?.substring(0, limit)}...` : word;
  return substring;
};

export const moneyTextField = (value, onChange) => {
  const isBlank = (str) => {
    return /^\s*$/.test(str);
  };
  const numberFormat = value
    ? value?.toString().charAt(value.length - 1) !== "."
      ? isBlank(value)
        ? ""
        : moneyFormat(value, 0)?.substring(1)
      : `${moneyFormat(value?.slice(0, -1), 0)?.substring(1)}.`
    : null;

  const handleChange = (e) => {
    const keyPress = +e.nativeEvent.data;
    if (Number.isNaN(keyPress) && e.nativeEvent.data !== ".") {
      onChange(value.replaceAll(",", "") || " ");
      return;
    }

    onChange(
      `${e.target.value.replaceAll(",", "")}${
        e.nativeEvent.data === "." ? "." : ""
      }`,
    );
  };

  return {
    value: numberFormat ?? 0,
    onChange: handleChange,
    type: "string",
    InputProps: {
      startAdornment: <InputAdornment position="start">₹</InputAdornment>,
    },
  };
};

/**
 * @description Get date range
 * @returns returns startDate and endDate
 */
export const getDateRange = (differenceInDays = 60, lastDate = Date.now()) => {
  const endDate = Math.round(lastDate / 1000);
  const startDate = endDate - differenceInDays * 24 * 3600;
  return { startDate, endDate };
};

/**
 * @description Get masked value
 * @return return masked value
 */
export const getMaskData = (value, notMaskedLength = 4, maskedWith = "*") => {
  if (value) {
    const slicedValue = value.slice(-notMaskedLength);
    const maskedNumber = slicedValue.padStart(value.length, maskedWith);
    return maskedNumber;
  }
  return "-";
};

/**
 * @description return true if direct order
 */

export const checkIsDirectOrder = (type) => {
  return [
    categoryType.DIRECT.toLowerCase(),
    categoryType.PLANT_SUPPLY.toLowerCase(),
  ].includes(type?.toLowerCase());
};

/**
 * @description return true if category type is private brand order
 */

export const checkIsPrivateBrand = (type) => {
  return [categoryType.PRIVATE_BRAND.toLowerCase()].includes(
    type?.toLowerCase(),
  );
};

/**
 * @description return true if category type is Distributed brand order
 */

export const checkIsDistributedOrder = (type) => {
  return categoryType.DISTRIBUTED.toLowerCase() === type?.toLowerCase();
};

export const convertDateArrayToDate = (dateArray) => {
  // Check if dateArray is null, undefined, or an empty array
  if (!dateArray) {
    return "-";
  }

  let convertedDate = `${("0" + dateArray[2]).slice(-2)}/${(
    "0" + dateArray[1]
  ).slice(-2)}/${dateArray[0]}`;
  return convertedDate;
};

export const getFileExtension = (url) => {
  // Use a regular expression to capture the extension after the last dot
  const match = url.match(/\.([a-zA-Z0-9]+)(\?|#|$)/);
  return match ? match[1] : null; // Return the extension if found, otherwise null
};

export const downloadPdf = (url, filename) => {
  // Create a temporary anchor element
  const link = document.createElement("a");
  link.href = url;
  link.download = filename || "file.pdf"; // Set the download attribute

  // Programmatically trigger the download
  document.body.appendChild(link);
  link.click();

  // Clean up the DOM
  document.body.removeChild(link);
};
