import {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  TextareaAutosize,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Controller, FormProvider, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import {
  Loader,
  TooltipLabel,
  DropdownV1,
  Clipboard,
  DatePickerV1,
  ButtonV1,
  ModalBox,
} from "components";
import { GridSkeleton } from "CustomStyledComponents";
import PaymentChip from "./PaymentChip";
import Comments from "./Comments";
import SellerTrack from "./SellerTrack";
import HoverBlock from "./HoverBlock";
import GridTable from "./GridTable";
import PaymentSummary from "./PaymentSummary";
import MultiSellerPaymentSummary from "./MultiSellerPaymentSummary";
import Discount from "./Discount";
import FileUpload from "./FileUpload";
import {
  ViewableItem,
  LineSeperator,
  RowSeparator,
  ColumnView,
} from "./CustomDetailComponents";
import BlockedAmountTable from "./BlockedAmountTable";
import theme from "themeProvider";

import {
  getTooltipDetails,
  getFormattedDiscount,
  getCashbackData,
  getMultiSellerSummary,
  getAmountsData,
} from "./orderDetailsServiceCall";
import {
  isValidQuantityForCreated,
  isValidQuantityForDelivery,
  getUpdatedData,
  makeCashbackData,
} from "./makeData";
import {
  setOrderDetailsAction,
  setCreditDetailsAction,
  setCustomerFreezeFlagAction,
} from "dataStore/orderDetails";
import {
  appendRupeeIcon,
  formatDate,
  moneyFormat,
  pascalCase,
  timeConvertor,
  validateNull,
  fetchTimeWithoutConversion,
} from "utils";
import { useFetchData, useToaster, useCategoryTypeCheck } from "hooks";
import { getCall_v2, postCall_v2, putCall_v2 } from "services";

import {
  channelOfOrderList,
  seller,
  customer,
  DetailsTab,
  Confirmed,
} from "./constants";
import { salesSpocList, opsSpocList, success, error } from "constants";
import { OrderDetails_constant } from "../constants";
import { ORDER_TRANSACTION_TYPES } from "pages/OrderList/constant";
import { reasonTypes, JOMS_API_BASE_URL } from "constants";

const { creditFieldsLabel, CREDIT, JODL_ORDER_STATUS } = DetailsTab;

const {
  CREDIT_TYPE,
  CAM_ID,
  CASH_AMOUNT,
  CREDIT_PERIOD,
  PENALTY_CHARGE,
  AVAILABLE_LIMIT,
  EXPIRY_DATE,
  CREDIT_AMOUNT,
  CREDIT_BYPASS,
} = creditFieldsLabel;

const { OrderStatus } = OrderDetails_constant;

const OrderPaymentType = ({ orderInfo }) => {
  return (
    <ViewableItem
      label={"Order payment type"}
      value={validateNull(
        ORDER_TRANSACTION_TYPES[orderInfo?.orderPaymentTransactionType],
      )}
    />
  );
};

const OrderSummary = forwardRef(
  ({ id, isOrderDetailsonMount, setIsInValidCashback }, ref) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const triggerToaster = useToaster();

    const methods = useFormContext();

    const { getValues, control, watch, reset, resetField } = methods;

    const [orderInfo, setOrderInfo] = useState("");
    const [formattedAmounts, setFormattedAmounts] = useState(null);
    const [recordedTransaction, setRecordedTransaction] = useState(null);
    const [multiSellerSummary, setMultiSellerSummary] = useState(false);
    const [creditData, setCreditData] = useState(null);

    const [customerCashback, setCustomerCashback] = useState(null);
    const [sellerCashback, setSellerCashback] = useState(null);
    const [discounts, setDiscounts] = useState(null);
    const [openBlockedAmountModal, setOpenBlockedAmountModal] = useState(false);

    const orderDetails = useSelector((state) => state.orderDetails);
    const { isDirect } = useCategoryTypeCheck();

    const setCashBackResponse = (orderInfo) => {
      const { sellerCashbackObject, customerCashbackObject } =
        getCashbackData(orderInfo);
      setSellerCashback(sellerCashbackObject);
      setCustomerCashback(customerCashbackObject);
    };

    /*
     * @description - order details fetch call
     */
    const { refetch: fetchOrderDetails, isFetching } = useFetchData(
      "order-details",
      `/oms/order/v2/number/${id}`,
      (res) => {
        const { data } = res;
        data.invoiceDateThreshold = 150;
        setOrderInfo(data);
        recordedTransactions();

        setDiscounts(getFormattedDiscount(data));
        setCashBackResponse(data);
      },
    );

    /**
     *  @description - transaction/payments data call
     */
    const { refetch: recordedTransactions } = useFetchData(
      "recorder-transaction",
      `/oms/payment/recordedTransaction?customerOrderNumber=${id}`,
      (res) => {
        const { data } = res;
        setRecordedTransaction(data);
        //render lineitems
        isOrderDetailsonMount();

        if (orderInfo?.isMultiSeller) {
          const summaryAmount = getMultiSellerSummary(orderInfo);
          setMultiSellerSummary(summaryAmount);
          setFormattedAmounts(summaryAmount?.formattedAmounts);
        } else {
          const orderSummary = getAmountsData(orderInfo, data);
          setFormattedAmounts(orderSummary);
        }
      },
    );

    // Fetch reasons for cancellations
    const { refetch: fetchReasons, data: reasonList } = useFetchData(
      "fetch-cancel-reason-list",
      `oms/rejection/get-reasons-by-type?type=${reasonTypes?.ORDER_CANCELLATION}`,
    );

    /**
     * @description - business unit list - master data
     */
    const { data: businessUnitList, refetch: fetchBusinessList } = useFetchData(
      "business-unit",
      "/oms/master-data/BUSINESS_UNIT",
    );

    /**
     *  @description - order type list - master data
     */
    const { refetch: fetchOrderType } = useFetchData(
      "order-type",
      "/oms/master-data/ORDER_TYPE",
    );

    //  @description - fetch order credit details
    const getOrderCreditDetails = async () => {
      try {
        const { data } = await getCall_v2(
          `${JOMS_API_BASE_URL}/external/v1/credit-details?orderNumber=${id}`,
        );
        setCreditData(data);
        //publish details to redux
        const details = {
          isCreditBlocked: data?.isCreditBlocked,
          creditBlockedReason: data?.creditBlockedReason,
        };

        dispatch(setCreditDetailsAction(details));
      } catch (ex) {
        console.error("Exception in fetching credit order details");
      }
    };

    //  @description - fetch credit limit details
    const getCreditLimitsForCustomer = async (orderInfo) => {
      try {
        const { data } = await getCall_v2(
          `${JOMS_API_BASE_URL}/external/v2/getCreditInfoByPan?pan=${orderInfo?.buyerDetails?.gstin.substr(
            2,
            10,
          )}&gstin=${orderInfo?.buyerDetails?.gstin}`,
        );
        //publish details to redux
        const isCustomerFreezed = {
          customerFreezed: data?.isCustomerCreditLimitBlocked,
          freezeReason: data?.blockedReason,
        };
        dispatch(setCustomerFreezeFlagAction(isCustomerFreezed));
      } catch (ex) {
        console.error("Exception in fetching credit order details");
      }
    };

    /**
     *  @description - get blocked amount
     */
    const {
      data: blockedAmountInfo,
      refetch: fetchBlockedAmount,
      isFetching: isBlockedAmountFetching,
    } = useFetchData(
      "blocked-amount",
      `oms/credit/getBlockedAmountInfo?id=${creditData?.id}`,
    );

    useEffect(() => {
      fetchBusinessList();
      fetchOrderType();
      fetchOrderDetails();
      fetchReasons();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (orderInfo?.jodlFailedReason) {
        const toasterType =
          orderInfo?.jodlOrderStatus === JODL_ORDER_STATUS.failed
            ? error
            : success;
        triggerToaster(orderInfo?.jodlFailedReason, toasterType);
      }
    }, [orderInfo]);

    useEffect(() => {
      (async () => {
        /* to dispatch redux action with payment data */
        if (orderInfo && recordedTransaction) {
          const customizedOrderData = {
            ...orderInfo,
            formattedAmounts: formattedAmounts,
            isDoFlowDisabled: orderInfo?.isDoFlowDisabled ?? false,
          };
          dispatch(setOrderDetailsAction(customizedOrderData));
          //invoke order credit details
          if (orderInfo?.paymentType === CREDIT) {
            await getOrderCreditDetails();
          }
          await getCreditLimitsForCustomer(orderInfo);
        }
      })();

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderInfo, formattedAmounts]);

    useImperativeHandle(ref, () => ({
      /*
       * @description - order update function exposed
       */
      async onOrderUpdate(isPayoutApplicable) {
        let isShipmentAlreadyCreated = false;
        Object.values(orderDetails).forEach((lineItem) => {
          if (lineItem?.lineItemShipments?.[0]?.shipmentId) {
            isShipmentAlreadyCreated = true;
          }
        });

        const updateData = getUpdatedData(
          getValues(),
          id,
          orderInfo,
          isPayoutApplicable,
        );
        const { amounts, orderStatus } = orderInfo;
        switch (orderStatus) {
          case "Created": {
            if (updateData?.orderStatus === OrderStatus?.placed) {
              if (isValidQuantityForCreated(amounts)) {
                await updateOrderDetails(updateData);
              } else {
                triggerToaster(`Payment for this order is not complete`, error);
              }
            }
            break;
          }
          case "Placed": {
            if (updateData?.orderStatus === OrderStatus.inProgress) {
              if (isShipmentAlreadyCreated) {
                await updateOrderDetails(updateData);
              } else {
                triggerToaster(
                  `Please create a shipment for this order`,
                  error,
                );
              }
            } else {
              await updateOrderDetails(updateData);
            }
            break;
          }
          case "Delivery in progress":
          case "Partially Delivered": {
            if (updateData?.orderStatus === OrderStatus.delivered) {
              const { lineItemDetails } = orderDetails;
              if (isValidQuantityForDelivery(lineItemDetails)) {
                await updateOrderDetails(updateData);
              } else {
                triggerToaster(
                  `Delivered Quantity + Short closed + Cancelled should be greater than equal to 95% of Ordered quantity for all line items`,
                  error,
                );
              }
            } else {
              await updateOrderDetails(updateData);
            }
            break;
          }
          default: {
            await updateOrderDetails(updateData);
            break;
          }
        }
        if (getValues("orderStatus") !== OrderStatus.cancelled) {
          const cashbackData = makeCashbackData(orderInfo, getValues());
          await postCall_v2(
            `/oms/order/cashback-update?customerOrderId=${orderInfo.id}`,
            cashbackData,
            "",
          );
        }
      },
      fetchOrderDetails: () => fetchOrderDetails(),
      getCreditData: () => creditData,
      fetchUpdatedValues: () => getValues(),
      resetField: (key) => resetField(key),
    }));

    const navigateToOrderList = useCallback(
      () => navigate(`/order-list/${id}?tab=orderDetails`),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [navigate],
    );

    const updateOrderDetails = async (orderDetails) => {
      try {
        await putCall_v2(
          "/joms/api/external/v1/order/order-update",
          orderDetails,
        );
        reset();
        triggerToaster(`Order ${id} updated successfully`, success);
        navigateToOrderList();
        fetchOrderDetails();
      } catch (err) {
        triggerToaster(err?.data?.errorMessage, error);
      }
    };

    const isCreditOrder = orderInfo?.paymentType === CREDIT;
    const { amounts, multiSellerOrderAmount = null } = orderInfo;

    const ADP_amount = multiSellerOrderAmount
      ? Object.values(multiSellerOrderAmount)[0]?.["ADP"]
      : amounts?.["ADP"];
    const cashAmount = ADP_amount?.amount?.centAmount / 100;

    const handleShowBreakUp = async () => {
      fetchBlockedAmount();
      setOpenBlockedAmountModal(true);
    };

    const { declined, cancelled } = OrderStatus;
    const isOrderCancelledOrDeclined =
      [declined, cancelled].includes(watch("orderStatus")) ||
      orderInfo?.orderStatus === cancelled;

    if (isFetching) return <Loader />;

    if (!orderInfo)
      return (
        <Typography
          variant="h5"
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          Something went wrong. Try after sometime.
        </Typography>
      );

    return (
      <>
        <FormProvider {...methods}>
          <Box display={"flex"} mb={12} as="form">
            <Box style={{ width: "75%" }} pl={4} pr={4}>
              <Box>
                {/* first row */}
                <RowSeparator>
                  <GridSkeleton
                    firstChild={
                      <ViewableItem
                        label={"Buyer order no."}
                        value={orderInfo?.orderNumber}
                      />
                    }
                    secondChild={
                      <ViewableItem
                        label={"Order date"}
                        value={`${formatDate(
                          orderInfo?.orderDate,
                        )}, ${timeConvertor(orderInfo?.orderDate + "Z")}`}
                      />
                    }
                    thirdChild={
                      <ColumnView label={"Buyer"}>
                        <TooltipLabel
                          placement="right"
                          title={
                            <HoverBlock data={getTooltipDetails(orderInfo)} />
                          }
                          labelChildren={
                            <Typography
                              fontSize={16}
                              fontWeight={"bold"}
                              color={theme.palette.secondary.main}
                              style={{ wordBreak: "break-word" }}
                            >
                              {pascalCase(orderInfo?.buyerDetails?.name)}
                            </Typography>
                          }
                        />
                      </ColumnView>
                    }
                  />
                </RowSeparator>
                {/* second row */}
                <RowSeparator>
                  <Box display={"flex"} flexDirection={"column"}>
                    <Box>
                      <GridSkeleton
                        firstChild={
                          <ColumnView label={"Payment status"}>
                            <PaymentChip
                              paymentStatus={orderInfo?.paymentStatus}
                              paymentCompletionDate={
                                orderInfo?.paymentCompletionDate
                              }
                            />
                          </ColumnView>
                        }
                        secondChild={
                          <ViewableItem
                            label={"Payment type"}
                            value={validateNull(orderInfo?.paymentType)}
                          />
                        }
                        thirdChild={
                          <Box>
                            <GridSkeleton
                              firstChild={
                                <ViewableItem
                                  label={"Order category"}
                                  value={orderInfo?.category}
                                />
                              }
                            />
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </RowSeparator>
                <RowSeparator>
                  <>
                    <GridSkeleton
                      firstChild={
                        isCreditOrder && (
                          <ViewableItem
                            label={CREDIT_TYPE}
                            value={validateNull(
                              `${creditData?.creditType ?? ""} - ${
                                creditData?.creditInstrument ?? ""
                              } ` ?? "",
                            )}
                          />
                        )
                      }
                      secondChild={
                        isCreditOrder && (
                          <ViewableItem
                            label={CAM_ID}
                            value={validateNull(
                              `${creditData?.creditLimitRefId ?? ""}` ?? "",
                            )}
                          />
                        )
                      }
                      thirdChild={
                        isCreditOrder && (
                          <ViewableItem
                            label={CREDIT_BYPASS}
                            value={validateNull(
                              `${orderDetails?.creditBlockBypassFlag ?? ""}` ??
                                "",
                            )}
                          />
                        )
                      }
                    />
                  </>
                </RowSeparator>
                <RowSeparator>
                  {!!orderInfo?.files &&
                    !!Object.keys(orderInfo?.files)?.length && (
                      <Box>
                        <Typography>Attachment</Typography>
                        <Grid container>
                          {Object.keys(orderInfo?.files).map((type) => (
                            <Grid item xs={4}>
                              <FileUpload
                                fileDetails={orderInfo?.files[type]}
                                type={type}
                                orderNumber={orderInfo?.orderNumber}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    )}
                </RowSeparator>
                {/*Second row(A) for Credit payment type */}
                {isCreditOrder && (
                  <RowSeparator>
                    <Box display={"flex"} flexDirection={"column"}>
                      <GridSkeleton
                        firstChild={
                          <ViewableItem
                            label={CASH_AMOUNT}
                            value={moneyFormat(cashAmount)}
                          />
                        }
                        secondChild={
                          <ViewableItem
                            label={CREDIT_PERIOD}
                            value={creditData?.creditDueDay}
                          />
                        }
                        thirdChild={
                          <ViewableItem
                            label={PENALTY_CHARGE}
                            value={validateNull(creditData?.penalRate)}
                          />
                        }
                      />
                    </Box>
                  </RowSeparator>
                )}
                {/*Second row(B) for Credit payment type */}
                {isCreditOrder && (
                  <RowSeparator>
                    <Box display={"flex"} flexDirection={"column"}>
                      <GridSkeleton
                        firstChild={
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "baseline",
                            }}
                          >
                            <ViewableItem
                              label={AVAILABLE_LIMIT}
                              value={moneyFormat(creditData?.creditUsable)}
                            />
                            <ButtonV1
                              onClick={handleShowBreakUp}
                              title="Show breakup"
                              variant="text"
                              disableRipple={true}
                              loading={isBlockedAmountFetching}
                              endIcon={
                                <img
                                  width={16}
                                  src="/assets/external-link.svg"
                                  style={{
                                    stroke: theme.palette.primary.main,
                                  }}
                                  alt="external link icon"
                                />
                              }
                              style={{
                                fontSize: 12,
                                padding: 5,
                                fontWeight: 600,
                              }}
                            />
                          </Box>
                        }
                        secondChild={
                          <ViewableItem
                            label={EXPIRY_DATE}
                            value={formatDate(creditData?.creditExpiry)}
                          />
                        }
                        thirdChild={
                          <ViewableItem
                            label={CREDIT_AMOUNT}
                            value={moneyFormat(creditData?.creditAmount ?? 0)}
                          />
                        }
                      />
                    </Box>
                  </RowSeparator>
                )}
                <LineSeperator />
                <RowSeparator>
                  <GridSkeleton
                    firstChild={
                      <Controller
                        control={control}
                        name={"isVerifiedPO"}
                        defaultValue={orderInfo?.isVerifiedPO}
                        render={({ field: { value, onChange } }) => (
                          <FormControlLabel
                            label={"Verify PI/PO"}
                            control={
                              <Checkbox
                                disabled={orderInfo?.isVerifiedPO}
                                value={value}
                                checked={!!value}
                                onChange={onChange}
                                disableRipple
                              />
                            }
                          />
                        )}
                      />
                    }
                    secondChild={
                      !!isDirect && (
                        <Controller
                          control={control}
                          name={"isVerifiedSO"}
                          defaultValue={orderInfo?.isVerifiedSO}
                          render={({ field: { value, onChange } }) => (
                            <FormControlLabel
                              label={"Verify SO"}
                              control={
                                <Checkbox
                                  disabled={orderInfo?.isVerifiedSO}
                                  value={value}
                                  checked={!!value}
                                  onChange={onChange}
                                  disableRipple
                                />
                              }
                            />
                          )}
                        />
                      )
                    }
                    thirdChild={
                      !!orderInfo?.salesforceOpportunityId && (
                        <ColumnView label={"Opportunity no."}>
                          <Box style={{ display: "flex" }}>
                            <Button
                              href={orderInfo?.salesforceOpportunityUrl}
                              target="_blank"
                              style={{
                                padding: 0,
                                height: "fit-content",
                              }}
                            >
                              {orderInfo?.salesforceOpportunityId}
                              <br />
                              {orderInfo?.opportunityString &&
                                `(${orderInfo?.opportunityString})`}
                            </Button>
                            <Clipboard
                              text={orderInfo?.salesforceOpportunityId}
                              message="Opportunity no. copied"
                              style={{ marginTop: -4 }}
                            />
                          </Box>
                        </ColumnView>
                      )
                    }
                  />
                </RowSeparator>
                {/* third row */}
                <RowSeparator>
                  <GridSkeleton
                    firstChild={
                      <ViewableItem
                        label={"Business unit"}
                        value={orderInfo?.businessUnit}
                      />
                    }
                    secondChild={
                      <ViewableItem
                        label={"Mode of order"}
                        value={orderInfo?.mode}
                      />
                    }
                    thirdChild={
                      <ViewableItem
                        label={"Delivery type"}
                        value={orderInfo?.deliveryType ?? "-"}
                      />
                    }
                  />
                </RowSeparator>
                {/* fourth row */}
                <RowSeparator>
                  <GridSkeleton
                    firstChild={
                      <ViewableItem
                        label={"Order status"}
                        value={orderInfo?.orderStatus}
                      />
                      // <ColumnView label={"Order status"}>
                      //   <Controller
                      //     control={control}
                      //     defaultValue={orderInfo?.orderStatus || ""}
                      //     name={"orderStatus"}
                      //     render={({ field: { value, onChange } }) => (
                      //       <DropdownV1
                      //         onChange={onChange}
                      //         itemList={orderInfo?.nextOrderStates.filter(
                      //           (ele) => ele.state !== Confirmed,
                      //         )}
                      //         valueKey="state"
                      //         value={value}
                      //         menuStyle={{ minWidth: 170 }}
                      //       />
                      //     )}
                      //   />
                      // </ColumnView>
                    }
                    secondChild={
                      orderInfo?.orderConfirmationDate && (
                        <ViewableItem
                          label={"Order confirmation date"}
                          value={`${formatDate(
                            orderInfo?.orderConfirmationDate,
                          )}, ${fetchTimeWithoutConversion(
                            orderInfo?.orderConfirmationDate,
                          )}`}
                        />
                      )
                    }
                    thirdChild={
                      orderInfo?.orderConfirmedBy && (
                        <ViewableItem
                          label={"Order confirmed by"}
                          value={orderInfo?.orderConfirmedBy}
                        />
                      )
                    }
                  />
                </RowSeparator>
                <GridSkeleton
                  firstChild={
                    <>
                      {!!(
                        orderInfo?.orderStatus === OrderStatus.cancelled ||
                        watch("orderStatus") === OrderStatus.cancelled
                      ) && (
                        <ColumnView
                          label={"Reason for Cancellation / Rejection"}
                        >
                          <Controller
                            control={control}
                            defaultValue={orderInfo?.reasonForCancellation}
                            name={"reasonForCancellation"}
                            render={({ field: { value, onChange } }) => (
                              <DropdownV1
                                onChange={onChange}
                                itemList={reasonList?.data}
                                displayKey="reason"
                                valueKey={"reason"}
                                value={value}
                                menuStyle={{ minWidth: 170, maxWidth: 170 }}
                              />
                            )}
                          />
                        </ColumnView>
                      )}
                      {watch("orderStatus") === OrderStatus.declined && (
                        <ColumnView label={"Reason for decline"}>
                          <Controller
                            control={control}
                            defaultValue={orderInfo?.reasonForDecline}
                            name={"reasonForDecline"}
                            render={({ field: { value, onChange } }) => (
                              <TextareaAutosize
                                value={value}
                                onChange={onChange}
                                minRows={1}
                                style={{
                                  background: "transparent",
                                  fontFamily: "inherit",
                                  resize: "none",
                                  padding: 10,
                                  borderRadius: 8,
                                  minWidth: 220,
                                }}
                              />
                            )}
                          />
                        </ColumnView>
                      )}
                      {!isOrderCancelledOrDeclined && (
                        <OrderPaymentType orderInfo={orderInfo} />
                      )}
                    </>
                  }
                  secondChild={
                    isOrderCancelledOrDeclined && (
                      <OrderPaymentType orderInfo={orderInfo} />
                    )
                  }
                />
                <RowSeparator></RowSeparator>
                <LineSeperator />
                {/* fifth row */}
                <RowSeparator>
                  <SellerTrack
                    orderInfo={orderInfo}
                    businessUnitList={businessUnitList}
                  />
                </RowSeparator>
                <LineSeperator />
                {/* sixth row */}
                <RowSeparator>
                  <GridSkeleton
                    firstChild={
                      <ColumnView label={"Ops SPOC"}>
                        <Controller
                          control={control}
                          defaultValue={orderInfo?.opsSpoc}
                          name={"opsSpoc"}
                          render={({ field: { value, onChange } }) => (
                            <DropdownV1
                              onChange={onChange}
                              itemList={opsSpocList}
                              value={value}
                              menuStyle={{ minWidth: 170 }}
                            />
                          )}
                        />
                      </ColumnView>
                    }
                  />
                </RowSeparator>
              </Box>
            </Box>
            <Box style={{ flex: 1 }}>
              {formattedAmounts && !multiSellerSummary && (
                <PaymentSummary data={formattedAmounts} />
              )}

              {multiSellerSummary && (
                <Box mb={8}>
                  <MultiSellerPaymentSummary
                    title={"Seller payment summary"}
                    orderSummary={multiSellerSummary}
                  />
                </Box>
              )}

              {!!discounts && <Discount data={discounts} />}

              {sellerCashback && (
                <Box mb={8} style={{ display: "none" }}>
                  <GridTable
                    data={sellerCashback}
                    title="Seller cashbacks"
                    type={seller}
                    verifyCashback={setIsInValidCashback}
                  />
                </Box>
              )}

              {customerCashback && (
                <Box mb={8}>
                  <GridTable
                    amounts={orderInfo?.amounts}
                    data={customerCashback}
                    title="Buyer cashbacks"
                    verifyCashback={setIsInValidCashback}
                    buyerCashback={
                      orderInfo?.buyerCashback
                        ? appendRupeeIcon(orderInfo?.buyerCashback)
                        : ""
                    }
                    type={customer}
                  />
                </Box>
              )}
            </Box>
          </Box>
          <ModalBox
            open={openBlockedAmountModal}
            onCloseModal={() => setOpenBlockedAmountModal(false)}
            width={"60%"}
          >
            <BlockedAmountTable
              onClose={() => setOpenBlockedAmountModal(false)}
              data={blockedAmountInfo?.data}
            />
          </ModalBox>
        </FormProvider>
      </>
    );
  },
);

export default OrderSummary;
