import * as Yup from "yup";
import {
  partialHold,
  release,
  shipmentStatus as shipmentStatusList,
} from "../constants";
// import { getStartDate, checkIsDirectOrder } from "utils";
import { getStartDate, checkIsDirectOrder } from "utils";

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export const ShipmentCreateSchema = (isDoFlowDisabled) => {
  return Yup.object().shape({
    shipmentMode: Yup.string().required("Shipment mode is required"),
    scheduledDispatchDate: Yup.date("Scheduled dispatch date is required")
      .required("Scheduled dispatch date is required")
      .typeError("Scheduled dispatch date is required")
      .when(
        "scheduledDispatchDateCondition",
        (scheduledDispatchDateCondition, schema) =>
          schema.min(
            scheduledDispatchDateCondition,
            `Scheduled dispatch date should not be less than ${scheduledDispatchDateCondition}`,
          ),
      ),
    // warehouseDetail: Yup.string().required("Pickup location is required"),
    driverName: Yup.string().max(15, "Max 15 characters are allowed"),
    truckReceiptNumber: Yup.string().max(15, "Max 15 characters are allowed"),
    truckNo: Yup.string().max(
      12,
      "Invalid truck no, max 12 characters are allowed",
    ),
    driverNo: Yup.number()
      .min(1000000000, "Enter a 10 digit number")
      .max(9999999999, "Enter a 10 digit number")
      .typeError("Only numbers allowed"),
    lineItemsDetails: Yup.array().of(
      Yup.object().shape({
        quantity: Yup.string(),
        alreadyPlannedQty: Yup.string().nullable(),
        enteredQuantity: Yup.number("Quantity is required")
          .required(`Quantity is required`)
          .typeError(`Quantity is required`)
          .test({
            name: "check enteredQty + plannedQty is more than actualQty*1.05",
            message: "Please enter valid quantity.",
            test(value, ctx) {
              const {
                quantity,
                alreadyPlannedQty,
                totalDoReleasedQty,
                totalQuantityShipped,
                totalDoQuantityShortClosed,
              } = ctx.parent;

              // If isDoFlowDisabled is true then value should be less than totalDoReleasedQty * 1.2(20%) -totalQuantityShipped;
              if (!isDoFlowDisabled) {
                const validValue = (
                  Number(totalDoReleasedQty ?? 0) * 1.2 -
                  Number(totalQuantityShipped) -
                  Number(totalDoQuantityShortClosed)
                ).toFixed(2);

                if (Boolean(Number(value)) && validValue < Number(value)) {
                  return false;
                }
                return true;
              } else {
                // If isDoFlowDisabled is false then orderQty 20% should be less alreadyPlannedQty + enteredQty
                if (
                  Number(quantity ?? 0) * 1.2 <
                  Number(alreadyPlannedQty) + Number(value)
                ) {
                  return false;
                }
                return true;
              }
            },
          }),
      }),
    ),
  });
};

export const ShipmentUpdateSchema = (orderDetails, shipmentLineItems) => {
  let shipmentStatusValue = "";
  return Yup.object().shape({
    shipmentStatus: Yup.string(),
    actualDispatchDate: Yup.date()
      .test(
        "within-threshold",
        `The date entered cannot be older than ${orderDetails?.invoiceDateThreshold} days from the Order Date`,
        function (value) {
          if (!value) return true; // Skip validation if no date provided
          const orderDate = new Date(getStartDate(orderDetails?.orderDate));
          const thresholdDate = new Date(orderDate);
          thresholdDate.setDate(
            orderDate.getDate() - orderDetails?.invoiceDateThreshold,
          );
          return value >= thresholdDate;
        },
      )
      .nullable()
      .default(undefined)
      .when("shipmentStatus", {
        is: (shipmentStatus) => {
          shipmentStatusValue = shipmentStatus;
          return [
            shipmentStatusList.delivered,
            shipmentStatusList.dispatched,
          ].includes(shipmentStatus);
        },
        then: Yup.date()
          .required("Dispatch date is required")
          .typeError("Dispatch date is required"),
      }),

    actualDeliveryDate: Yup.date()
      .nullable()
      .default(undefined)
      .when("actualDispatchDate", {
        is: (actualDispatchDate) => actualDispatchDate,
        then: Yup.date()
          .nullable()
          .test(
            "within-threshold",
            `The date entered cannot be older than ${orderDetails?.invoiceDateThreshold} days from the Order Date`,
            function (value) {
              if (!value) return true; // Skip validation if no date provided
              const orderDate = new Date(getStartDate(orderDetails?.orderDate));
              const thresholdDate = new Date(orderDate);
              thresholdDate.setDate(
                orderDate.getDate() - orderDetails?.invoiceDateThreshold,
              );
              return value >= thresholdDate;
            },
          ),
      })
      .when("shipmentStatus", {
        is: (shipmentStatus) => shipmentStatus === shipmentStatusList.delivered,
        then: Yup.date()
          .required("Delivery date is required")
          .typeError("Delivery date is required"),
      }),
    invoiceNo: Yup.string(),
    invoiceComment: Yup.string().when("holdType", {
      is: (holdType) => holdType !== release,
      then: Yup.string().required("Please enter the Invoice comments"),
    }),
    payoutHoldAmount: Yup.number().when("holdType", {
      is: (holdType) => holdType === partialHold,
      then: Yup.number()
        .typeError("Please enter the hold amount")
        .required("Please enter the hold amount"),
    }),
    confirmInvoiceNo: Yup.string()
      .matches(
        /^[^_]*$/, // Allows any characters except underscores
        "Invoice numbers shouldn’t contain any special characters",
      )
      .max(16, "Please add an invoice with less than 16 digits")
      .oneOf([Yup.ref("invoiceNo"), null], "Values do not match, please check")
      .when("shipmentStatus", {
        is: (shipmentStatus) =>
          shipmentStatus === shipmentStatusList.invoiced ||
          (checkIsDirectOrder(orderDetails?.category) &&
            shipmentStatus === shipmentStatusList.dispatched),
        then: Yup.string()
          .required("Invoice no. is required")
          .matches(
            /^[a-zA-Z0-9/-]*$/,
            "Only alphanumeric characters, '/' and '-' are allowed",
          ),
      }),
    processingCharge: Yup.string(),
    confirmprocessingCharge: Yup.string().oneOf(
      [Yup.ref("processingCharge"), null],
      "Values do not match, please check",
    ),
    freightCharge: Yup.string(),
    confirmfreightCharge: Yup.string().oneOf(
      [Yup.ref("freightCharge"), null],
      "Values do not match, please check",
    ),
    loadingCharge: Yup.string(),
    confirmloadingCharge: Yup.string().oneOf(
      [Yup.ref("loadingCharge"), null],
      "Values do not match, please check",
    ),
    otherCharge: Yup.string(),
    confirmotherCharge: Yup.string().oneOf(
      [Yup.ref("otherCharge"), null],
      "Values do not match, please check",
    ),
    zohoServiceCharges: Yup.array().of(
      Yup.object().shape({
        confirmAmount: Yup.string().test({
          name: "Values match check",
          message: "Values do not match, please check",
          test(value, ctx) {
            if (+ctx?.parent.amount !== +value) {
              return false;
            }
            return true;
          },
        }),
      }),
    ),
    lineItemsDetails: Yup.array().of(
      Yup.object().shape({
        confirmInvoicedQty: Yup.string().when("shipmentStatus", {
          is: () => {
            return (
              shipmentStatusValue === shipmentStatusList.readyForDispatch ||
              shipmentStatusValue === shipmentStatusList.readyForPickUp
            );
          },
          then: Yup.string().required("Confirm quantity invoiced is required"),
        }),
        invoicedRatePerUnit: Yup.string("Unit rate is required")
          .required(`Unit rate is required`)
          .typeError(`Unit rate is required`)
          .test({
            name: "check unit rate",
            message: "Please enter valid unit rate.",
            test(value, ctx) {
              const { path } = ctx;
              const index = parseInt(path.split("[")[1].split("]")[0], 10);
              const lineItem = orderDetails.lineItemDetails?.find(
                (item) => item?.lineItemId === index,
              );
              const shipmentLineItem = shipmentLineItems?.find(
                (item) => item?.lineitemId === index,
              );
              const lineItemPerUnitPrice =
                shipmentLineItem?.invoiceDelivery?.invoiceInfo?.perUnitRate ??
                lineItem?.discountedPrice?.centAmount / 100;
              if (
                Number(lineItemPerUnitPrice ?? 0) * 1.5 < Number(value) ||
                Number(lineItemPerUnitPrice ?? 0) * 0.5 > Number(value)
              ) {
                return false;
              }
              return true;
            },
          }),
        confirmInvoicedRatePerUnit: Yup.string().oneOf(
          [Yup.ref("invoicedRatePerUnit"), null],
          "Values do not match, please check",
        ),
        discountPerUnit: Yup.string(),
        confirmDiscountPerUnit: Yup.string().oneOf(
          [Yup.ref("discountPerUnit"), null],
          "Values do not match, please check",
        ),
      }),
    ),
    tcsCheckBox: Yup.boolean(),
    invoicedTcsAmount: Yup.number(),
    confirmInvoicedTcsAmount: Yup.number().when("tcsCheckBox", {
      is: true,
      then: Yup.number()
        .required("Please enter TCS amount")
        .positive("Please enter amount greater than 0")
        .typeError("Please enter valid number")
        .oneOf(
          [Yup.ref("invoicedTcsAmount"), null],
          "Values do not match, please check",
        ),
    }),
    driverName: Yup.string()
      .nullable()
      .max(15, "Max 15 characters are allowed"),
    truckReceiptNumber: Yup.string()
      .nullable()
      .max(15, "Max 15 characters are allowed"),
    truckNumber: Yup.string()
      .nullable()
      .max(12, "Invalid truck no, max 12 characters are allowed"),
    driverNumber: Yup.string()
      .nullable()
      .test({
        name: "Validate mobile no.",
        message: "Enter a 10 digit number",
        test(value) {
          if (value) {
            if (value.length === 10) {
              return true;
            }
            return false;
          }
          return true;
        },
      }),
    invoiceDate: Yup.date().nullable().default(undefined),
    confirmInvoiceDate: Yup.date()
      .nullable()
      .default(undefined)
      .when("invoiceDate", {
        is: (invoiceDate) => invoiceDate && isValidDate(invoiceDate),
        then: Yup.date()
          .typeError("Please enter valid date in confirmation field")
          .required("Please enter valid date in confirmation field")
          .test({
            name: "Confirm invoice date to be equal",
            message: "Values do not match, please check",
            test(value, ctx) {
              let { invoiceDate } = ctx.parent;
              let invDate = new Date(invoiceDate);
              let confirmInvoiceDate = new Date(value);
              let formattedInvDate = new Intl.DateTimeFormat("fr-CA", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }).format(invDate);
              let formattedConfirmInvDate = new Intl.DateTimeFormat("fr-CA", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }).format(confirmInvoiceDate);
              if (formattedInvDate !== formattedConfirmInvDate) {
                return false;
              }
              return true;
            },
          }),
      })
      .when("shipmentStatus", {
        is: (shipmentStatus) =>
          shipmentStatus === shipmentStatusList.invoiced ||
          (checkIsDirectOrder(orderDetails?.category) &&
            shipmentStatus === shipmentStatusList.dispatched),
        then: Yup.date()
          .required("Invoice date is required")
          .typeError("Invoice date is required"),
      }),
  });
};
