import { Box, TextField, Grid, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import PropTypes from "prop-types";

import { ButtonV1, DatePickerV1, DropdownV1 } from "components";
import { formatDate } from "utils";

const CustomTextField = ({ name, label, control }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <TextField
          size="small"
          label={label}
          onChange={onChange}
          value={value}
        />
      )}
    />
  );
};

const Filters = ({ onFilterSubmit, totalRecords = 0 }) => {
  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      invoiceNumber: "",
      customerGSTIN: "",
      plantCode: "",
      soNumber: "",
      status: "",
      invoiceDateMin: "",
      invoiceDateMax: "",
      batchNumber: "",
      uploadDateMin: "",
      uploadDateMax: "",
    },
  });

  const handleFilter = (data) => {
    onFilterSubmit(data); // Pass form values to the parent component
  };

  const handleReset = () => {
    reset(); // Reset the form to default values
    onFilterSubmit({});
  };

  const convertDateFormat = (date) => {
    const [day, month, year] = date.split("/");
    return `${year}-${month}-${day}`;
  };

  return (
    <Box>
      <Typography style={{ fontSize: 16, fontWeight: "bold", marginLeft: 10 }}>
        {`All uploaded records (${totalRecords})`}
      </Typography>
      <Box p={2} as="form" onSubmit={handleSubmit(handleFilter)}>
        <Grid container spacing={2} columns={12} mb={4}>
          <Grid item xs={14} md={2}>
            <CustomTextField
              name={"invoiceNumber"}
              label="Invoice number"
              control={control}
            />
          </Grid>
          <Grid item xs={14} md={2}>
            <CustomTextField
              name={"customerGSTIN"}
              label="Customer GSTIN"
              control={control}
            />
          </Grid>
          <Grid item xs={14} md={2}>
            <CustomTextField
              name={"plantCode"}
              label="Plant code"
              control={control}
            />
          </Grid>
          <Grid item xs={14} md={2}>
            <CustomTextField
              name={"soNumber"}
              label="SO number"
              control={control}
            />
          </Grid>
          <Grid item xs={14} md={2}>
            <CustomTextField
              name={"batchNumber"}
              label="Batch No."
              control={control}
            />
          </Grid>
          <Grid item xs={14} md={2}>
            <Controller
              control={control}
              name={"status"}
              render={({ field: { value, onChange } }) => (
                <DropdownV1
                  sx={{
                    width: "80%",
                  }}
                  listType="constants"
                  placeholder="Status"
                  itemList={[
                    "Created",
                    "Approved",
                    "Pending",
                    "Error",
                    "SO Mapped",
                  ]}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={14} md={2}>
            <Controller
              control={control}
              name={"uploadDateMin"}
              render={({ field: { value, onChange } }) => (
                <DatePickerV1
                  label="Upload date from"
                  value={value}
                  onChange={(ev) => onChange(convertDateFormat(formatDate(ev)))}
                  disableFuture={true}
                />
              )}
            />
          </Grid>
          <Grid item xs={14} md={2}>
            <Controller
              control={control}
              name={"uploadDateMax"}
              render={({ field: { value, onChange } }) => (
                <DatePickerV1
                  label="Upload date till"
                  value={value}
                  onChange={(ev) => onChange(convertDateFormat(formatDate(ev)))}
                  disableFuture={true}
                />
              )}
            />
          </Grid>
          <Grid item xs={14} md={2}>
            <Controller
              control={control}
              name={"invoiceDateMin"}
              render={({ field: { value, onChange } }) => (
                <DatePickerV1
                  label="Invoice date from"
                  value={value}
                  onChange={(ev) => onChange(convertDateFormat(formatDate(ev)))}
                  disableFuture={true}
                />
              )}
            />
          </Grid>
          <Grid item xs={14} md={2}>
            <Controller
              control={control}
              name={"invoiceDateMax"}
              render={({ field: { value, onChange } }) => (
                <DatePickerV1
                  label="Invoice date till"
                  value={value}
                  onChange={(ev) => onChange(convertDateFormat(formatDate(ev)))}
                  disableFuture={true}
                />
              )}
            />
          </Grid>

          <Grid item xs={14} md={2}>
            <Box display={"flex"} justifyContent={"flex-start"} gap={8}>
              <ButtonV1
                title={"Submit"}
                type="submit"
                style={{ width: "100%" }}
                disabled={!isDirty}
              />

              <ButtonV1
                title={"Reset"}
                variant="outlined"
                disabled={!isDirty}
                style={{ width: "100%" }}
                onClick={handleReset}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Filters;

Filters.propTypes = {
  onFilterSubmit: PropTypes.func,
  totalRecords: PropTypes.number,
};
