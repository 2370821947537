import { useState, useCallback, useEffect, useRef, useMemo } from "react";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";

import { ButtonV1, ExportCta, TooltipLabel } from "components";
import InvoiceTable from "./InvoiceTable";
import { QuickFilter } from "../components";

import { getCall_v2, postCall_v2 } from "services";
import { useFetchData, useToaster } from "hooks";
import { downloadCsv } from "utils";

import { success, error } from "constants";
import { FinancePayouts } from "../../constants";

const initialQuickFilter = "all";

const {
  quick_filter,
  msgStatements: { ERROR_MSG },
} = FinancePayouts;
const rowsPerPage = 50;

const endUrl = `oms/payout/seller-payout-summaries`;

const InvoiceSeller = ({ transactionType, filters }) => {
  const [selectedQuickFilter, setSelectedQuickFilter] =
    useState(initialQuickFilter);
  const [page, setPage] = useState(0);
  const [filterCount, setFilterCount] = useState({});
  const [selectedPayout, setSelectedPayout] = useState([]);
  const [isPaymentChecked, setPaymentChecked] = useState([]);
  const triggerToaster = useToaster();
  const theme = useTheme();

  const pendingFilterParams = useMemo(() => {
    return {
      ...filters,
      filterType: selectedQuickFilter,

      limit: rowsPerPage,
      transactionType,
    };
  }, [filters, selectedQuickFilter, transactionType]);

  const payoutTableRef = useRef(null);

  const onSuccess = (res) => {
    let filterCountRef = { ...filterCount };
    filterCountRef[selectedQuickFilter] = res?.data?.totalCount;
    setFilterCount(filterCountRef);
  };

  const {
    refetch: getPendingPayouts,
    data: pendingTransactions,
    isFetching,
  } = useFetchData(
    ["invoice-pending-payouts", pendingFilterParams],
    endUrl,
    onSuccess,
    null,
    { ...pendingFilterParams, offset: page * rowsPerPage },
  );

  /**
   * @description set filters on click of quick filters
   */
  const handleQuickFilter = (value) => {
    setSelectedQuickFilter(value);
  };

  /**
   * @description fetch pending payouts during page load
   */
  useEffect(() => {
    if (page) {
      setPage(0);
    } else {
      getPendingPayouts();
    }
  }, [pendingFilterParams]);

  useEffect(() => {
    getPendingPayouts();
  }, [page]);

  /**
   * @description Handle pagination
   */
  const handlePageChange = useCallback((_, pageNumber) => {
    setPage(pageNumber);
    resetSelectItems(); // Reset selected Items on page change
  }, []);

  /**
   * @description reset selected items and parent checkbox
   */
  const resetSelectItems = useCallback(() => {
    payoutTableRef?.current?.resetParentCheckBox();
    setSelectedPayout([]);
  }, []);

  const getSelectedSummaryData = (data) => {
    setSelectedPayout(data);
  };

  const onInitiatePayout = async (isApproved) => {
    if (selectedPayout.length) {
      let selectedData = selectedPayout.map((ele, i) => {
        return {
          isPayoutRequired: isPaymentChecked[i] === false ? false : true,
          id: ele.payoutId,
          customerOrderNumber: ele?.customerOrdNumber,
          fundAccountId:
            ele?.selectedFundAccountId ??
            (ele?.defaultFundAccountId ||
              ele?.fundAccountDetails?.sellerAccount),
        };
      });
      try {
        const res = await postCall_v2("oms/payout/approve-payouts", {
          payoutRequestDTOList: selectedData,
          nextState: {
            state: isApproved ? "PAYMENT_APPROVED" : "PAYMENT_ON_HOLD",
          },
        });
        if (res.status === 200) {
          getPendingPayouts();
          triggerToaster(
            isApproved
              ? `Transaction successfully approved`
              : "Transaction moved to On hold",
            success,
          );
          resetSelectItems(); // Reset selected Items on approved or hold
          setSelectedPayout([]);
        } else {
          triggerToaster(ERROR_MSG, error);
        }
      } catch (err) {
        triggerToaster(ERROR_MSG, error);
      } finally {
        setPaymentChecked([]);
      }
    }
  };

  const onExportCSV = async () => {
    try {
      const payoutUrl = `/oms/payout/download/seller-payout-summaries`;
      const { data } = await getCall_v2(payoutUrl, {
        ...pendingFilterParams,
        limit: null,
      });
      triggerToaster(`Downloading file`, success);
      downloadCsv(data, "pending_seller_payout.csv");
    } catch (err) {
      console.error(`Error in download, ${err}`);
      triggerToaster(`Please try again later`, error);
    }
  };

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={4}
      >
        <Box>
          <Typography fontSize={20} fontWeight={600} mb={2}>
            Pending payouts
          </Typography>
          <Box display={"flex"}>
            {Object.keys(quick_filter).map((elem, ind) => (
              <QuickFilter
                key={ind}
                label={quick_filter[elem]}
                value={elem}
                onClick={handleQuickFilter}
                selectedFilter={selectedQuickFilter}
                filterCount={filterCount}
              />
            ))}
          </Box>
        </Box>
        <Box sx={{ flexDirection: "row", display: "flex" }}>
          <Box
            mr={3}
            sx={{ display: "flex", alignItems: "center", marginBottom: -4 }}
          >
            <ExportCta onExport={onExportCSV} />
          </Box>
          <TooltipLabel
            customStyle={{
              "& .MuiTooltip-tooltip": {
                border: `1px solid ${theme.palette.grey["400"]}`,
              },
              "& .MuiTooltip-arrow": {
                "&::before": {
                  backgroundColor: "white",
                  border: `1px solid ${theme.palette.grey["400"]}`,
                },
              },
            }}
            title={
              <>
                <ButtonV1
                  title={"Approve"}
                  size={"small"}
                  variant="text"
                  onClick={() => onInitiatePayout(true)}
                />
                <Divider />
                <ButtonV1
                  title={"Hold"}
                  size={"small"}
                  variant="text"
                  onClick={() => onInitiatePayout()}
                />
              </>
            }
            labelChildren={
              <Box
                style={{
                  padding: 8,
                  backgroundColor: !selectedPayout?.length
                    ? theme.palette.grey["400"]
                    : theme.palette.primary.main,
                  color: !selectedPayout?.length
                    ? theme.palette.text.disabled
                    : theme.palette.common.white,
                  borderRadius: 4,
                  display: "flex",
                  pointerEvents: !selectedPayout?.length && "none",
                }}
              >
                <Typography fontWeight={"600"}>Action</Typography>
                <KeyboardArrowDown fontSize="small" />
              </Box>
            }
          />
        </Box>
      </Box>
      <InvoiceTable
        ref={payoutTableRef}
        pendingTransactions={pendingTransactions?.data}
        rowsPerPage={rowsPerPage}
        pageNumber={page}
        loading={isFetching}
        onPageChange={handlePageChange}
        passSelectedData={getSelectedSummaryData}
        getPendingPayouts={getPendingPayouts}
        selectedPayout={selectedPayout}
      />
    </>
  );
};

export default InvoiceSeller;
