import { Grid } from "@mui/material";

const GridSkeleton = ({ firstChild, secondChild, thirdChild }) => {
  return (
    <Grid container mb={4}>
      <Grid item xs={4}>
        {firstChild}
      </Grid>
      <Grid item xs={4}>
        {secondChild}
      </Grid>
      <Grid item xs={4}>
        {thirdChild}
      </Grid>
    </Grid>
  );
};

export default GridSkeleton;
