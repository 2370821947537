export const paymentTypeList = [
  { value: "ADVANCE", label: "ADVANCE" },
  { value: "PART", label: "PART" },
  { value: "FINAL", label: "FINAL" },
];

export const blockObjectHeader = [
  "Created at",
  "Block type",
  "Advance amount",
  "Credit amount",
  "Block object status",
];

export const blockType = {
  ADVANCE: "Order Confirmation",
  DO: "Dispatch",
};

export const successMessage =
  "Payment requested, transaction will be updated soon";

export const cancelled = "Cancelled";
export const declined = "Declined";

export const paymentType = {
  CASH_AND_CARRY: "Cash & Carry",
};
