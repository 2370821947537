import { Box, TextField, Grid } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import PropTypes from "prop-types";

import { ButtonV1, DropdownV1 } from "components";
import { STATUS_STATES } from "pages/SupplyPanel/constant";

const CustomTextField = ({ name, label, control }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <TextField
          size="small"
          label={label}
          onChange={onChange}
          value={value}
          style={{ width: "100%" }}
        />
      )}
    />
  );
};

const Filters = ({ onFilterSubmit }) => {
  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      orderNumber: "",
      customerGstin: "",
      status: "",
    },
  });

  const handleFilter = (data) => {
    onFilterSubmit(data); // Pass form values to the parent component
  };

  const handleReset = () => {
    reset(); // Reset the form to default values
    onFilterSubmit({});
  };

  return (
    <Box>
      <Box p={2} as="form" onSubmit={handleSubmit(handleFilter)}>
        <Grid container spacing={2} columns={12}>
          <Grid item xs={14} md={2}>
            <CustomTextField
              name={"orderNumber"}
              label="Order ID"
              control={control}
            />
          </Grid>
          <Grid item xs={14} md={2}>
            <Controller
              control={control}
              name={"status"}
              render={({ field: { value, onChange } }) => (
                <DropdownV1
                  itemList={Object.values(STATUS_STATES)}
                  value={value}
                  onChange={onChange}
                  listType="array"
                  placeholder={"Status"}
                />
              )}
            />
          </Grid>
          <Grid item xs={14} md={2}>
            <CustomTextField
              name={"customerGstin"}
              label="Customer GSTIN"
              control={control}
            />
          </Grid>
          <Grid item xs={14} md={1.5}>
            <ButtonV1
              title={"Submit"}
              type="submit"
              style={{ width: "100%" }}
              disabled={!isDirty}
            />
          </Grid>
          <Grid item xs={14} md={1.5}>
            <ButtonV1
              title={"Reset"}
              variant="outlined"
              disabled={!isDirty}
              style={{ width: "100%" }}
              onClick={handleReset}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Filters;

Filters.propTypes = {
  onFilterSubmit: PropTypes.func,
  totalRecords: PropTypes.number,
};
