import { Box, Chip, Typography, styled } from "@mui/material";
import PropTypes from "prop-types";

import { QUICK_FILTERS } from "../constant";

const QuickChip = styled(Chip)(({ theme }) => ({
  borderRadius: 4,
  minWidth: 60,
  ".MuiChip-outlinedDefault": {
    borderColor: theme.palette.common.black,
    color: theme.palette.common.black,
  },
  ".MuiChip-filled": {
    border: `1px solid ${theme.palette.grey[400]}`,
    color: theme.palette.grey[700],
    backgroundColor: theme.palette.grey[200],
  },
}));

const QuickFilter = ({ onQuickFilter, state }) => {
  const handleSelect = (elem) => {
    onQuickFilter(elem);
  };

  return (
    <Box display={"flex"} sx={{ gap: 3 }}>
      <Typography sx={{ fontWeight: 600 }}>Quick filters:</Typography>
      {Object.keys(QUICK_FILTERS).map((item) => (
        <QuickChip
          key={item}
          label={item}
          size="small"
          variant={state === item ? "outlined" : "filled"}
          onClick={() => handleSelect(item)}
        />
      ))}
    </Box>
  );
};

QuickFilter.propTypes = {
  onQuickFilter: PropTypes.func,
  state: PropTypes.string,
};

export default QuickFilter;
