import { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  IconButton,
  Typography,
  Paper,
  useTheme,
  TablePagination,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

import { GridView } from "./components";
import ErrorView from "../components/ErrorView";

import { BULK_SHIPMENT } from "./constants";
import { Loader, PaginationAction } from "components";
import {
  decimalRoundOff,
  formatDate,
  moneyFormat,
  pascalCase,
  validateNull,
} from "utils";

const { REVIEW_TABLE_HEADER } = BULK_SHIPMENT;

const ViewOnly = ({ label, value, type = "string" }) => {
  const getFormattedValue = () => {
    let formattedValue = "";
    if (type === "number") {
      formattedValue = decimalRoundOff(value);
    } else if (type === "money") {
      formattedValue = moneyFormat(value);
    } else {
      formattedValue = validateNull(value);
    }
    return formattedValue;
  };

  return (
    <Box>
      <Typography style={{ fontSize: 14, lineHeight: 2 }}>{label}</Typography>
      <Typography style={{ fontWeight: "bold", fontSize: 14 }}>
        {getFormattedValue()}
      </Typography>
    </Box>
  );
};

const Row = ({ row }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const { sapShipmentUploadRequest } = row;
  const errors = row?.validationErrorMsg || [];

  const groupedChildren = () => {
    if (!sapShipmentUploadRequest) return [];
    const entries = Object.entries(sapShipmentUploadRequest);
    const groups = [];
    for (let i = 0; i < entries.length; i += 6) {
      groups.push(entries.slice(i, i + 6));
    }
    return groups.map((group) => ({
      firstChild: group[0] ? (
        <ViewOnly
          key={group[0][0]}
          label={pascalCase(group[0][0])}
          value={group[0][1]}
        />
      ) : null,
      secondChild: group[1] ? (
        <ViewOnly
          key={group[1][0]}
          label={pascalCase(group[1][0])}
          value={group[1][1]}
        />
      ) : null,
      thirdChild: group[2] ? (
        <ViewOnly
          key={group[2][0]}
          label={pascalCase(group[2][0])}
          value={group[2][1]}
        />
      ) : null,
      fourthChild: group[3] ? (
        <ViewOnly
          key={group[3][0]}
          label={pascalCase(group[3][0])}
          value={group[3][1]}
        />
      ) : null,
      fifthChild: group[4] ? (
        <ViewOnly
          key={group[4][0]}
          label={pascalCase(group[4][0])}
          value={group[4][1]}
        />
      ) : null,
      sixthChild: group[5] ? (
        <ViewOnly
          key={group[5][0]}
          label={pascalCase(group[5][0])}
          value={group[5][1]}
        />
      ) : null,
    }));
  };

  return (
    <>
      {/* Parent Row */}
      <TableRow>
        <TableCell>{validateNull(row.sapBuyerGstin)}</TableCell>
        <TableCell>{validateNull(row.sapCustomerName)}</TableCell>
        <TableCell>{validateNull(row.soNumber)}</TableCell>
        <TableCell>{validateNull(row.soLineItemNumber)}</TableCell>
        <TableCell>{validateNull(row.invoiceNumber)}</TableCell>
        <TableCell>{validateNull(row.status)}</TableCell>
        <TableCell>
          {row.createdDate ? formatDate(row.createdDate) : "-"}
        </TableCell>
        <TableCell>{validateNull(row.fileUploadedBy)}</TableCell>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>
      {/* Collapsible Content */}
      <TableRow>
        <TableCell
          colSpan={REVIEW_TABLE_HEADER.length}
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: theme.palette.common.white,
          }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              margin={2}
              paddingBottom={4}
              sx={{
                borderBottom: `1px solid ${theme.palette.grey["400"]}`,
              }}
            >
              {groupedChildren().length ? (
                groupedChildren().map((children, groupIndex) => (
                  <GridView
                    key={groupIndex}
                    style={{ padding: 2, marginTop: groupIndex > 0 ? 8 : 0 }}
                    {...children}
                  />
                ))
              ) : (
                <Typography>No data available</Typography>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <ErrorView errors={errors} />
    </>
  );
};

const ReviewContent = ({
  bulkShipmentData,
  onPageChange,
  rowsPerPage,
  isLoading,
}) => {
  const bulkList = bulkShipmentData?.rawBulkShipmentUploadDTOS;
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {REVIEW_TABLE_HEADER.map((header) => (
              <TableCell key={header}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {((!isLoading && bulkList) || []).map((row, index) => (
            <Row key={index} row={row} />
          ))}
          {!isLoading && !bulkList?.length && (
            <TableRow>
              <TableCell colSpan={REVIEW_TABLE_HEADER.length + 1}>
                <Typography textAlign={"center"}>No data found</Typography>
              </TableCell>
            </TableRow>
          )}
          {isLoading && (
            <TableRow>
              <TableCell colSpan={REVIEW_TABLE_HEADER.length + 1}>
                <Loader />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {!!bulkList?.length && (
        <TablePagination
          rowsPerPageOptions={[rowsPerPage]}
          rowsPerPage={rowsPerPage}
          page={Math.ceil(bulkShipmentData?.offset / rowsPerPage)}
          count={bulkShipmentData?.totalCount}
          onPageChange={onPageChange}
          sx={{ display: "flex", flexDirection: "column-reverse" }}
          ActionsComponent={PaginationAction}
        />
      )}
    </TableContainer>
  );
};

export default ReviewContent;
