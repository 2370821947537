import {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
  lazy,
} from "react";
import { Box, styled } from "@mui/material";

import EditableTransaction from "./EditableTransaction";
import FileDragAndDrop from "../components/FileDragAndDrop";
import OverlayLoader from "../components/OverlayLoader";

import { deleteCall, postCall_v2, putCall } from "services";
import { responseDataMapper, uploadCsvDataMapper } from "./makeData";
import { useFetchData, useToaster } from "hooks";

import { ORDER_BLOCK } from "./constant";
import { success, error } from "constants";
const PendingTransaction = lazy(() => import("./PendingTransaction"));

const rowsPerPage = 50;

const getInvalidTransactionData = (data) => {
  const errorList = [];
  const transactionList = Object.values(data)?.map((item) => {
    const errorResponseMapper = responseDataMapper(item[0]);
    const errorYupName = Object.keys(errorResponseMapper).filter(
      (item) => !!errorResponseMapper[item],
    );
    errorList?.push(errorYupName);

    return responseDataMapper(item[1]);
  });
  return { transactionList, errorList };
};

const UploadBoxLayout = styled(Box)(({ theme }) => ({
  border: 1,
  borderStyle: "solid",
  borderColor: theme.palette.grey[900],
  borderRadius: 12,
  width: "100%",
  paddingRight: 24,
  paddingLeft: 24,
  paddingTop: 20,
  paddingBottom: 20,
}));

const OrderBlock = ({ filters }) => {
  const uploadFileRef = useRef(null);
  const scrollRef = useRef(null);

  const [editPayout, setEditPayout] = useState(uploadCsvDataMapper());
  const [errorTransaction, setErrorTransaction] = useState();
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [page, setPage] = useState(0);

  const triggerToaster = useToaster();
  const { data, isFetching, refetch } = useFetchData(
    "invoice-seller-payout-pending",
    `/oms/off-oms-payout/order-block/list?limit=${rowsPerPage}&offset=${
      page * rowsPerPage
    }`,
    null,
    null,
    { ...filters },
  );

  const handleReset = useCallback(() => {
    setEditPayout(uploadCsvDataMapper());
    setIsBulkUpload(false);
    setErrorTransaction();
  }, []);

  const handleUploadFile = useCallback((data) => {
    setIsBulkUpload(true);
    const payoutData = uploadCsvDataMapper(
      data.filter((item, index) => {
        if (data.length - 1 === index && !item["Order ID"]) {
          return false;
        }
        return true;
      }),
    );
    setEditPayout(payoutData);
  }, []);

  const handleApiResponseMapper = (data) => {
    const payoutData = data.map((item) => responseDataMapper(item));
    setEditPayout(payoutData);
  };

  const handleDeleteTransaction = async (id) => {
    const data = await deleteCall(
      `oms/off-oms-payout/order-block/delete?payoutId=${id}`,
    );

    if (data) {
      triggerToaster(
        `Successfully deleted the payout for Payout Id: ${id}`,
        success,
      );
      refetch();
    } else {
      triggerToaster(`Payout Id: ${id}, is not deleted`, error);
    }
  };

  const handleMenuClick = useCallback((action, trans) => {
    const actions = ORDER_BLOCK.action;
    switch (actions[action]) {
      case actions.edit:
        scrollRef.current.scroll();
        setIsBulkUpload(false);
        handleApiResponseMapper([trans]);
        break;
      case actions.delete:
        handleDeleteTransaction(trans.id);
        break;
      default:
        console.error("Out of action finance payout out of option");
        break;
    }
  }, []);

  const putUpdateTransaction = async (request, id) => {
    try {
      const data = await putCall(
        `/oms/off-oms-payout/order-block/update?payoutId=${id}`,
        request,
      );

      if (!Object.values(data).length) {
        triggerToaster(
          `Successfully updated the payout for Payout Id: ${id}`,
          success,
        );
        handleReset();
        refetch();
      } else {
        triggerToaster(`Payout Id: ${id} is not updated`, error);

        const { transactionList, errorList } = getInvalidTransactionData(data);

        setErrorTransaction(errorList);
        setEditPayout(transactionList);
      }
    } catch (err) {
      triggerToaster("Something went wrong. Try after sometime", error);
    }
  };

  const postTransaction = async (request) => {
    try {
      const { data } = await postCall_v2(
        "oms/off-oms-payout/order-block",
        request,
      );

      if (Object.values(data).length) {
        triggerToaster(
          `${Object.values(data).length} transaction is not added`,
          error,
        );
        refetch();

        const { transactionList, errorList } = getInvalidTransactionData(data);

        setErrorTransaction(errorList);
        setEditPayout(transactionList);
      } else {
        triggerToaster(`Successfully added the payout`, success);
        handleReset();
        refetch();
      }
    } catch (err) {
      triggerToaster(`Unable to add the transaction`, error);
    }
  };

  const handleSubmitEditTransaction = async (request) => {
    const id = editPayout?.[0]?.id;
    setIsUploading(true);
    if (id !== undefined) {
      await putUpdateTransaction(request, id);
    } else {
      await postTransaction(request);
    }

    setIsUploading(false);
    isBulkUpload && uploadFileRef.current.resetFile();
    setIsBulkUpload(false);
  };

  const handleResetTransaction = useCallback(() => {
    handleReset();
    uploadFileRef.current.resetFile();
  }, []);

  useEffect(() => {
    if (page) {
      setPage(0);
    } else {
      refetch();
    }
  }, [filters]);

  useEffect(() => {
    refetch();
  }, [page]);

  // @description Handle pagination
  const handlePageChange = useCallback(
    (_event, pageNumber) => setPage(pageNumber),
    [],
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 5,
        paddingTop: 4,
        paddingBottom: 10,
      }}
    >
      <OverlayLoader isLoading={isUploading}>
        <UploadBoxLayout>
          <Box>
            <FileDragAndDrop
              ref={uploadFileRef}
              invokeOMSPayout={handleUploadFile}
            />
            <EditableTransaction
              ref={scrollRef}
              data={editPayout}
              errorList={errorTransaction}
              onSubmit={handleSubmitEditTransaction}
              onReset={handleResetTransaction}
              isBulkUpload={isBulkUpload}
            />
          </Box>
        </UploadBoxLayout>
      </OverlayLoader>
      <Suspense fallback={<Box> Loading... </Box>}>
        <PendingTransaction
          data={data?.data?.payoutSummaries}
          onClickMenu={handleMenuClick}
          isFetching={isFetching}
          totalCount={data?.data?.totalCount}
          onPageChange={handlePageChange}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      </Suspense>
    </Box>
  );
};

export default OrderBlock;
