import { Box, Typography, Grid } from "@mui/material";
import theme from "themeProvider";

const HoverBlock = ({ data, title, firstColumnXs, secondColumnXs }) => {
  return (
    <>
      {data && (
        <Box width={300} borderColor={"lightgray"} p={2}>
          <Box mb={4}>
            <Typography fontWeight={"bold"}> {title}</Typography>
          </Box>
          {Object.entries(data).map(([key, value], i) => (
            <Grid
              container
              key={key}
              style={{
                border: Object.entries(data).length === i + 1 && 0,
                borderBottomStyle: "dotted",
                borderColor: theme.palette.text.secondary,
              }}
              mb={2}
            >
              <Grid item xs={firstColumnXs}>
                <Typography fontSize={16}>{key} : </Typography>
              </Grid>
              <Grid item xs={secondColumnXs}>
                <Typography fontSize={16}> {value} </Typography>
              </Grid>
            </Grid>
          ))}
        </Box>
      )}
    </>
  );
};

export default HoverBlock;
