import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import OldBulkShipment from "./BulkShipment";
import BulkShipment from "./BulkShipmentV1";
import PlantSupplyTab from "./PlantSupplyTab";
import DoShortClose from "./DoShortClose";
import ShipmentCreationPlantSupply from "./ShipmentCreationPlantSupply";
import ShipmentCreationJodl from "./ShipmentCreationJodl";

import { userModule } from "constants";
import { plantSupplyTabs } from "./constant";

// const IS_NEW_BULK_SHIPMENT_FLOW = true;
const TabPanel = ({ children, value, selectedTab, tabName, ...rest }) => {
  return (
    <Box
      role="tab-panel"
      hidden={value !== selectedTab}
      id={`tab-panel-${value}`}
      aria-labelledby={`tab-${value}`}
      {...rest}
    >
      {value === selectedTab && <>{children}</>}
    </Box>
  );
};

const SupplyPanel = () => {
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = searchParams.get("tab");
  const userModules = useSelector((state) => state.userData.modules);
  const [tab, setTab] = useState(
    currentTab ?? plantSupplyTabs.PLANT_SUPPLY_TAB,
  );

  useEffect(() => {
    if (!searchParams.get("tab")) {
      searchParams.set("tab", plantSupplyTabs.PLANT_SUPPLY_TAB);
      setSearchParams(searchParams);
    }
  }, []);

  const handleUpdateQueryParam = useCallback(
    (tab) => {
      searchParams.set("tab", tab);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const handleTabChange = (_event, newValue) => {
    setTab(newValue);
    handleUpdateQueryParam(newValue);
  };

  //verify user access for short closer
  const isShortCloseApprover = useMemo(
    () =>
      userModules?.some(({ name }) => name === userModule.doShortCloseApproval),
    [userModules],
  );

  //verify user access for bulk upload
  const shipmentBulkUploadAccess = useMemo(
    () =>
      userModules?.some(({ name }) => name === userModule.shipmentBulkUpload),
    [userModules],
  );

  //verify user access shipment creation plant supply
  const shipmentCreatePlantSupplyAccess = useMemo(
    () =>
      userModules?.some(({ name }) => name === userModule.shipmentCreationJopl),
    [userModules],
  );

  //verify user access shipment creation jodl
  const shipmentCreateJodlAccess = useMemo(
    () =>
      userModules?.some(({ name }) => name === userModule.shipmentCreationJodl),
    [userModules],
  );

  return (
    <Box padding={3} px={8}>
      <Typography variant="h3">Supply panel</Typography>
      <Box sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 5 }}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          sx={{
            ">.MuiTabs-scroller": {
              ">.MuiTabs-indicator": {
                backgroundColor: theme.palette.common.black,
              },

              ">.MuiTabs-flexContainer>.Mui-selected": {
                fontWeight: 600,
                color: theme.palette.common.black,
              },
            },
          }}
        >
          <Tab
            label={plantSupplyTabs.PLANT_SUPPLY_TAB}
            value={plantSupplyTabs.PLANT_SUPPLY_TAB}
          />
          <Tab
            label={plantSupplyTabs.OLD_BULK_SHIPMENT}
            value={plantSupplyTabs.OLD_BULK_SHIPMENT}
          />
          {shipmentBulkUploadAccess && (
            <Tab
              label={plantSupplyTabs.BULK_SHIPMENT}
              value={plantSupplyTabs.BULK_SHIPMENT}
            />
          )}
          {shipmentCreatePlantSupplyAccess && (
            <Tab
              label={plantSupplyTabs.SHIPMENT_CREATION_PLANT_SUPPLY}
              value={plantSupplyTabs.SHIPMENT_CREATION_PLANT_SUPPLY}
            />
          )}
          {shipmentCreateJodlAccess && (
            <Tab
              label={plantSupplyTabs.SHIPMENT_CREATION_JODL}
              value={plantSupplyTabs.SHIPMENT_CREATION_JODL}
            />
          )}

          {isShortCloseApprover && (
            <Tab
              label={plantSupplyTabs.DO_SHORTCLOSE}
              value={plantSupplyTabs.DO_SHORTCLOSE}
            />
          )}
        </Tabs>
      </Box>
      <TabPanel value={plantSupplyTabs.OLD_BULK_SHIPMENT} selectedTab={tab}>
        <OldBulkShipment />
      </TabPanel>
      <TabPanel value={plantSupplyTabs.BULK_SHIPMENT} selectedTab={tab}>
        <BulkShipment />
      </TabPanel>
      <TabPanel
        value={plantSupplyTabs.SHIPMENT_CREATION_PLANT_SUPPLY}
        selectedTab={tab}
      >
        <ShipmentCreationPlantSupply />
      </TabPanel>
      <TabPanel
        value={plantSupplyTabs.SHIPMENT_CREATION_JODL}
        selectedTab={tab}
      >
        <ShipmentCreationJodl />
      </TabPanel>
      <TabPanel value={plantSupplyTabs.PLANT_SUPPLY_TAB} selectedTab={tab}>
        <PlantSupplyTab />
      </TabPanel>
      {isShortCloseApprover && (
        <TabPanel value={plantSupplyTabs.DO_SHORTCLOSE} selectedTab={tab}>
          <DoShortClose />
        </TabPanel>
      )}
    </Box>
  );
};

export default SupplyPanel;
