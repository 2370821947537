import { useEffect, useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Typography, Skeleton, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import { Controller, useForm } from "react-hook-form";

import GridSkeleton from "./GridSkelton";
import {
  ModalBox,
  ViewOnly,
  TableWrapper,
  InputField,
  ButtonV1,
  Loader,
  DialogBox,
  DropdownV1,
  DatePickerV1,
} from "components";
import { getCall_v2, postCall } from "services";
import { useFetchData, useToaster, useCategoryTypeCheck } from "hooks";

import theme from "themeProvider";
import {
  getFixedValue,
  appendRupeeIcon,
  moneyFormat,
  validateNull,
} from "utils";
import { success, error, categoryType } from "constants";
import {
  paymentTypeList,
  successMessage,
  cancelled,
  declined,
  paymentType,
} from "./constants";
import BlockObject from "./BlockObject";

const SectionTitle = (props) => {
  return (
    <StyledBox>
      <Typography variant="h3">{props.title}</Typography>
    </StyledBox>
  );
};
const StyledBox = styled(Box)`
  && {
    padding-left: 0.2rem;
    margin-top: 0.7rem;
  }
`;

const ButtonBox = styled(Box)`
  display: flex;
  margin-top: 1rem;
`;

const Payment = ({ getTotalInvoiceAmount }) => {
  const [isModalOpen, setOpenModal] = useState(false);
  const [transactions, setTransactions] = useState({ recordedTransaction: [] });
  const orderDetails = useSelector((state) => state.orderDetails);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [customerGSTIN, setCustomerGSTIN] = useState();
  const [isLedgerBalanceTriggered, setIsLedgerBalanceTriggered] = useState();
  const [customerBalance, setCustomerBalance] = useState();
  const navigation = useNavigate();
  const triggerToaster = useToaster();
  const { isDirect } = useCategoryTypeCheck();
  const theme = useTheme();

  const {
    getValues,
    control,
    formState: { isDirty },
  } = useForm({
    mode: "onTouched",
  });

  const { id } = useParams();

  useEffect(() => {
    fetchTransaction();
    if (isDirect) {
      refetchAdvanceUtilised();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSuccess = (res) => {
    setTransactions(res.data);
    const { customerGstin, totalInvoiceAmount } = res?.data;
    setCustomerGSTIN(customerGstin);
    getCustomerBalance(customerGstin);
    getTotalInvoiceAmount(totalInvoiceAmount);
  };

  const getCustomerBalance = async (gstin) => {
    try {
      if (gstin) {
        setIsLedgerBalanceTriggered(true);
        const { data } = await getCall_v2(`/oms/payment/balance/${gstin}`);
        setCustomerBalance(data?.balance);
        setIsLedgerBalanceTriggered(false);
      }
    } catch (error) {
      console.error("getCustomerBalance is throwing error :: ", error);
    }
  };

  const { data: advanceUtilised, refetch: refetchAdvanceUtilised } =
    useFetchData(
      "advance-utilised",
      `/oms/payment/advanceUtilisation?orderNumber=${id}`,
    );
  const onError = (error) => {
    console.log("error", error);
  };
  const { isLoading, refetch: fetchTransaction } = useFetchData(
    "recorded-transaction",
    `/oms/payment/recordedTransaction?customerOrderNumber=${id}`,
    onSuccess,
    onError,
  );

  const onSendRequest = async () => {
    const requestPaymentBody = {
      orderNumber: id,
      paymentType: getValues("paymentType"),
      amount: getValues("amount"),
      orderType: orderDetails?.category,
    };

    const requestPaymentRes = await postCall(
      `/oms/payment/requestPayment`,
      requestPaymentBody,
      {},
      () => {
        triggerToaster(`Payment creation failed. Pls try again`, error);
      },
    );
    setOpenModal(false);
    if (requestPaymentRes) {
      fetchTransaction();
      triggerToaster(successMessage, success);
    }
  };

  const handleCheckBanlance = useCallback(() => {
    const encode = btoa(customerGSTIN);
    customerGSTIN && navigation(`/ledger?buyer=${encode}&tab=buyer`);
  }, [customerGSTIN, navigation]);

  const handleExitPage = () => {
    setIsConfirmModalOpen(false);
    setOpenModal(false);
  };

  const handleCloseModal = () => {
    if (isDirty) {
      setIsConfirmModalOpen(true);
    } else {
      setOpenModal(false);
    }
  };

  const CustomViewOnly = ({ label, value }) => {
    return (
      <ViewOnly
        rest={{ flexDirection: "column", gap: 4 }}
        valueStyle={{ width: "45%", fontSize: 16 }}
        labelStyle={{
          width: "45%",
          color: theme.palette.text.secondary,
          fontSize: 14,
        }}
        label={label}
        value={value}
      />
    );
  };

  const { totalInvoiceAmount } = transactions;
  const orderSummary = orderDetails ? orderDetails.formattedAmounts : {};

  /**
   * @description calculate advance percentage content once
   */
  const showAdvancePercentage = useMemo(() => {
    const percent = orderDetails?.advancePercentage;
    if (
      orderDetails?.paymentType?.trim() === paymentType.CASH_AND_CARRY.trim()
    ) {
      if (percent) {
        return `(${percent}% advance in payments)`;
      }
      return "";
    } else {
      return `(${percent}% advance in payments)`;
    }
  }, [orderDetails]);

  // if (isLoading) return <Loader />;

  return (
    <Box>
      {/* {!isCreditOrder && (
        <ButtonBox style={{ justifyContent: "center" }}>
          <ButtonV1
            title="Request payment"
            onClick={() => setOpenModal(true)}
            variant="text"
            disabled={
              orderDetails?.orderStatus === cancelled ||
              orderDetails?.orderStatus === declined
            }
            color="secondary"
          />
        </ButtonBox>
      )} */}
      <Box display={"flex"} justifyContent={"space-between"}>
        <SectionTitle title="Payment summary" />
        <ButtonV1
          title="View Ledger"
          variant="outlined"
          onClick={handleCheckBanlance}
        />
      </Box>
      {/* Column section on top */}
      <Box mb={10}>
        <GridSkeleton
          firstChild={
            <CustomViewOnly
              label="Total order amount"
              value={`${
                orderSummary && orderSummary["Total order amount"]
              } ${showAdvancePercentage}`}
            />
          }
          secondChild={
            <CustomViewOnly
              label="Invoice amount"
              value={
                totalInvoiceAmount &&
                appendRupeeIcon(totalInvoiceAmount?.displayAmount)
              }
            />
          }
          thirdChild={
            <CustomViewOnly
              label="Paid amount"
              value={orderSummary && orderSummary["Total paid amount"]}
            />
          }
        />
        <GridSkeleton
          firstChild={
            <CustomViewOnly
              label="Pending amount"
              value={orderSummary && orderSummary["Pending amount"]}
            />
          }
          secondChild={
            <CustomViewOnly
              label="Excess amount"
              value={orderSummary && orderSummary["Positive tolerance amount"]}
            />
          }
          thirdChild={
            <CustomViewOnly
              label="Customer balance"
              value={
                orderSummary && (
                  <Box
                    display={"flex"}
                    sx={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {!isLedgerBalanceTriggered ? (
                      moneyFormat(customerBalance)
                    ) : (
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: 16, width: 100 }}
                      />
                    )}
                  </Box>
                )
              }
            />
          }
        />

        {orderDetails?.category === categoryType?.DIRECT && (
          <GridSkeleton
            firstChild={
              <CustomViewOnly
                label="Advance utilised"
                value={appendRupeeIcon(advanceUtilised?.data?.amount || "0")}
              />
            }
          />
        )}
      </Box>

      {/* block object section */}
      <BlockObject orderNumber={id} />
      {/* modal box for request payment */}
      <ModalBox open={isModalOpen} onCloseModal={handleCloseModal} width="40%">
        <Box>
          <Typography variant="h2" mb={5} style={{ textAlign: "center" }}>
            Send payment request
          </Typography>
          <Box mb={5} display={"flex"} alignItems={"center"}>
            <Typography fontWeight={600} sx={{ width: "30%" }}>
              Payment type
            </Typography>
            <Controller
              control={control}
              name="paymentType"
              render={({ field: { value, onChange } }) => (
                <DropdownV1
                  sx={{ width: "30%" }}
                  customStyle={{ minWidth: "32%", maxWidth: "50%" }}
                  placeholder="Payment type"
                  itemList={paymentTypeList}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Box>
          <Controller
            control={control}
            name="amount"
            defaultValue={"0"}
            render={({ field: { value, onChange } }) => (
              <InputField
                label="Amount"
                type="number"
                labelStyle={{ width: "30%" }}
                value={getFixedValue(value)}
                onChange={onChange}
              />
            )}
          />
          <ButtonBox style={{ justifyContent: "center", marginTop: "2rem" }}>
            <ButtonV1
              title="Send request"
              onClick={onSendRequest}
              variant="contained"
            />
          </ButtonBox>
        </Box>
      </ModalBox>
      <DialogBox
        handleClose={() => setIsConfirmModalOpen(false)}
        open={isConfirmModalOpen}
        handleConfirm={handleExitPage}
      />
    </Box>
  );
};

export default Payment;
