import { useEffect, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { InfoOutlined } from "@mui/icons-material";

import PropTypes from "prop-types";

import { Divider, HyperLink, ModalBox } from "components";

import { useFetchData } from "hooks";
import { moneyFormat } from "utils";
import { selectedCustomerPropTypes } from "./propTypes";

const CustomViewOnly = ({
  label,
  value,
  icon,
  bottomBorder = true,
  rightBorder = true,
}) => {
  const theme = useTheme();
  return (
    <Grid
      item
      md={4}
      padding={2}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRight: rightBorder && `1px solid ${theme.palette.grey["400"]}`,
        borderBottom: bottomBorder && `1px solid ${theme.palette.grey["400"]}`,
      }}
    >
      <Typography ml={8} mt={2} variant="body2">
        {label}
      </Typography>
      {icon && (
        <Box display={"flex"} alignItems={"baseline"} mr={10}>
          <Typography mt={2} mr={2} fontWeight={"bold"} variant="body2">
            {moneyFormat(value)}
          </Typography>
          {icon && <Box>{icon}</Box>}
        </Box>
      )}
      {!icon && (
        <Typography mr={17} mt={2} fontWeight={"bold"} variant="body2">
          {moneyFormat(value)}
        </Typography>
      )}
    </Grid>
  );
};

const CustomerBalanceDetails = ({ selectedCustomer }) => {
  const [isBlockModalOpen, setBlockModalOpen] = useState(false);
  const theme = useTheme();

  const { refetch: fetchLedgerSummary, data: ledgerSummary } = useFetchData(
    "fetch-ledger-summary",
    `ledger-service/api/external/v1/fetch-ledger-summary-details?gstin=${selectedCustomer?.gstin}`,
  );

  const handleBlockModal = () => {
    setBlockModalOpen(!isBlockModalOpen);
  };

  useEffect(() => {
    fetchLedgerSummary();
  }, [selectedCustomer]);

  const ledgerData = ledgerSummary?.data?.data;

  const pan = selectedCustomer?.gstin.substr(2, 10);

  return (
    <>
      <Box mt={4} mb={4} style={{ display: "flex", flexDirection: "row" }}>
        <Typography fontWeight="bold" fontSize={18}>
          {selectedCustomer?.companyName}
        </Typography>
        <Divider />
        <Typography
          style={{
            fontSize: 18,
          }}
        >
          GSTIN:
        </Typography>
        <Typography
          style={{
            color: theme.palette.grey["1000"],
            fontSize: 18,
            marginLeft: 8,
          }}
          component="span"
        >
          {selectedCustomer?.gstin}
        </Typography>
        <Divider />
        <Typography
          style={{
            fontSize: 18,
          }}
        >
          PAN:
        </Typography>
        <Typography
          style={{
            color: theme.palette.grey["1000"],
            fontSize: 18,
            marginLeft: 8,
          }}
          component="span"
        >
          {pan}
        </Typography>
      </Box>

      <Grid
        container
        spacing={2}
        mt={2}
        style={{
          border: `1px solid ${theme.palette.grey["400"]}`,
          borderRadius: 10,
        }}
      >
        <CustomViewOnly
          label="Ledger Balance"
          value={ledgerData?.ledgerBalance}
        />
        <CustomViewOnly
          label="Total incoming payments"
          value={ledgerData?.totalIncomingPayments}
        />
        <CustomViewOnly
          label="Total cashback issued"
          value={ledgerData?.totalCashbackIssued}
          rightBorder={false}
        />
        <CustomViewOnly
          label="Blocked Amount"
          value={ledgerData?.totalBlockedAmount}
          icon={
            <IconButton
              disableRipple
              size="small"
              sx={{ padding: 0 }}
              color="primary"
              onClick={handleBlockModal}
            >
              <InfoOutlined fontSize="small" />
            </IconButton>
          }
        />
        <CustomViewOnly
          label="Total Invoice Amount"
          value={ledgerData?.totalInvoiceAmount}
        />
        <CustomViewOnly
          label="Total Payout Done"
          value={ledgerData?.totalPayoutDone}
          rightBorder={false}
        />
        <CustomViewOnly label="AR Not Due" value={ledgerData?.arNotDue} />
        <CustomViewOnly
          label="Total Debit Note Amount"
          value={ledgerData?.totalDebitNoteAmount}
        />
        <Grid md={4} borderBottom={`1px solid ${theme.palette.grey["400"]}`} />
        <CustomViewOnly
          label="Usable Ledger Balance"
          value={ledgerData?.totalUsableLedgerBalance}
          bottomBorder={false}
        />
        <CustomViewOnly
          label="Total Credit Note Amount"
          value={ledgerData?.totalCreditNoteAmount}
          bottomBorder={false}
        />
      </Grid>

      <ModalBox
        open={isBlockModalOpen}
        onCloseModal={handleBlockModal}
        width="30%"
        hideCrossIcon
      >
        <Box padding={2}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box display={"flex"}>
              <Typography fontSize={18} mr={3}>
                {selectedCustomer?.companyName}
              </Typography>
              <Typography color={theme.palette.grey["1000"]} fontSize={18}>
                {selectedCustomer?.gstin}
              </Typography>
            </Box>
            <IconButton onClick={handleBlockModal}>
              <CloseIcon color="primary" />
            </IconButton>
          </Box>

          <Box display={"flex"} mt={4}>
            <Typography mr={2} fontWeight={700}>
              Blocked amount:
            </Typography>
            <Typography>
              {moneyFormat(ledgerData?.totalBlockedAmount)}
            </Typography>
          </Box>
          <Box mt={5}>
            <Paper
              sx={{
                maxWidth: 800,
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              {/* Header */}
              <Grid
                container
                sx={{
                  backgroundColor: `${theme.palette.grey["A100"]}`,
                  borderBottom: `1px solid ${theme.palette.grey["400"]}`,
                }}
              >
                <Grid
                  padding={3}
                  item
                  xs={6}
                  borderRight={`1px solid ${theme.palette.grey["400"]}`}
                >
                  <Typography fontWeight="600">Order id</Typography>
                </Grid>
                <Grid padding={3} item xs={6}>
                  <Typography fontWeight="600">Blocked amount</Typography>
                </Grid>
              </Grid>

              {/* Order Items */}
              {!!ledgerData?.blockedOrderDetails &&
                Object.entries(ledgerData?.blockedOrderDetails).map(
                  ([orderId, amount], index) => (
                    <Box>
                      <Grid
                        container
                        sx={{
                          borderBottom:
                            index !==
                              Object.keys(ledgerData?.blockedOrderDetails)
                                .length -
                                1 && `1px solid ${theme.palette.grey["400"]}`,
                        }}
                      >
                        <Grid
                          padding={3}
                          item
                          xs={6}
                          borderRight={`1px solid ${theme.palette.grey["400"]}`}
                        >
                          <HyperLink title={orderId} />
                        </Grid>
                        <Grid padding={3} item xs={6}>
                          <Typography>{moneyFormat(amount)}</Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  ),
                )}
            </Paper>
          </Box>
        </Box>
      </ModalBox>
    </>
  );
};

export default CustomerBalanceDetails;

CustomerBalanceDetails.propTypes = {
  selectedCustomer: PropTypes.shape(selectedCustomerPropTypes).isRequired,
};
