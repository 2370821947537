import { useEffect, useState, useMemo, useRef } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  Typography,
} from "@mui/material";

import {
  HyperLink,
  PaginationAction,
  Loader,
  ModalBox,
  ButtonV1,
  TextField,
} from "components";
import { StyledTableRow } from "CustomStyledComponents";
import ZohoOrderLifeCycle from "./components/ZohoOrderLifeCycle";
import { useFetchData } from "hooks";
import { moneyFormat, validateNull } from "utils";

const ROWS_PER_PAGE = 50;

const OrderListView = () => {
  const [zohoOrdersList, setZohoOrdersList] = useState([]);
  const [page, setPageCount] = useState(0);
  const [filterOrder, setFilterOrder] = useState(null);
  const textFieldRef = useRef(null);

  const toggleOrderInfoVisibility = (orderNumber) => {
    setFilterOrder(orderNumber);
    if (orderNumber === null || orderNumber === "") {
      textFieldRef.current.value = "";
    }
  };

  const onPageChange = (_, pageNumber) => {
    setPageCount(pageNumber);
  };

  const updateZohoOrderState = (response) => {
    const { data } = response;
    if (data?.isSuccess) {
      setZohoOrdersList(data);
    }
  };

  const { refetch: fetchZohoOrders, isFetching } = useFetchData(
    "fetch-zoho-order",
    `/joms/api/external/v1/zoho-order-view-list?page=${
      page + 1
    }&offset=${ROWS_PER_PAGE}`,
    updateZohoOrderState,
  );

  useEffect(() => {
    fetchZohoOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getValidStatus = (status) => {
    const result = {
      PENDING: "Pending",
      SUCCESSFUL: "Success",
      CANCELLED: "Cancelled",
    };
    return result[status] ?? "-";
  };
  const tableFields = useMemo(() => {
    return [
      {
        title: "Order number ",
        field: "ordNumber",
        render: (ele) => <HyperLink title={ele?.ordNumber} />,
      },
      {
        title: "Order created date",
        field: "orderCreatedDate",
        render: (ele) => (
          <Typography fontSize={14}>
            {ele?.orderCreatedDate
              ? ele?.orderCreatedDate
              : validateNull(ele?.orderCreatedDate)}
          </Typography>
        ),
      },
      {
        title: "Order state",
        field: "",
        render: (ele) => (
          <Typography style={{ width: 120 }} fontSize={14}>
            {validateNull(ele?.currentState?.orderStateInfo?.label)}
          </Typography>
        ),
      },
      {
        title: "Payment type",
        field: "orderPaymentType",
        render: (ele) => (
          <Typography fontSize={14}>{ele?.orderPaymentType}</Typography>
        ),
      },
      {
        title: "Cashback amount",
        field: "cashbackAmount",
        render: (ele) => (
          <Typography fontSize={14}>
            {moneyFormat(ele?.cashbackAmount)}
          </Typography>
        ),
      },
      // {
      //   title: "Refund amount",
      //   field: "refundAmount",
      //   render: (ele) => (
      //     <Typography fontSize={14}>
      //       {moneyFormat(ele?.refundAmount)}
      //     </Typography>
      //   ),
      // },
      {
        title: "Zoho release status",
        field: "releaseStatus",
        render: (ele) => (
          <Typography fontSize={14}>
            {getValidStatus(ele?.releaseStatus)}
          </Typography>
        ),
      },

      {
        title: "Zoho cashback status",
        field: "cashbackStatus",
        render: (ele) => (
          <Typography fontSize={14}>
            {ele?.cashbackStatus ? getValidStatus(ele?.cashbackStatus) : "-"}
          </Typography>
        ),
      },
      {
        title: "Zoho delivery release date",
        field: "zohoOrderDeliveryReleaseDate",
        render: (ele) => (
          <Typography fontSize={14}>
            {ele?.zohoOrderDeliveryReleaseDate
              ? ele?.zohoOrderDeliveryReleaseDate
              : validateNull(ele?.cashbackAmount)}
          </Typography>
        ),
      },
    ];
  }, []);

  return (
    <Box p={2}>
      <Box display={"flex"} gap={6} mb={6}>
        <TextField
          value={filterOrder}
          size={"small"}
          placeholder="Query by order no."
          id="order-search"
          inputRef={textFieldRef}
        />
        <ButtonV1
          title={"Submit"}
          color="primary"
          variant="contained"
          disabled={filterOrder?.length < 10}
          onClick={() => toggleOrderInfoVisibility(textFieldRef.current.value)}
        />
        {/* <ButtonV1
          title="Reset"
          variant="outlined"
          disabled={filterOrder==null}
          onClick={resetFilters}
        /> */}
      </Box>
      <Box component={Paper}>
        <TableContainer
          sx={{
            minWidth: 700,
            maxHeight: "calc(100vh - 120px)",
          }}
        >
          <Table stickyHeader>
            <TableHead>
              {tableFields.map((el) => (
                <TableCell key={el.field}>{el.title}</TableCell>
              ))}
            </TableHead>
            <TableBody>
              {isFetching || !zohoOrdersList?.orderReleaseInfoList?.length ? (
                <StyledTableRow>
                  <TableCell colSpan={tableFields.length} align="center">
                    {isFetching ? (
                      <Loader sx={{ marginTop: 0 }} />
                    ) : (
                      <Typography textAlign={"center"}>
                        No data found
                      </Typography>
                    )}
                  </TableCell>
                </StyledTableRow>
              ) : (
                zohoOrdersList?.orderReleaseInfoList.map((row) => (
                  <StyledTableRow
                    key={row?.ordNumber}
                    onClick={() => toggleOrderInfoVisibility(row?.ordNumber)}
                  >
                    {tableFields?.map((el) => (
                      <TableCell key={el?.field}>{el.render(row)}</TableCell>
                    ))}
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!!zohoOrdersList?.orderReleaseInfoList?.length && (
          <TablePagination
            rowsPerPageOptions={[ROWS_PER_PAGE]}
            rowsPerPage={ROWS_PER_PAGE}
            page={page}
            count={zohoOrdersList?.totalCount ?? 0}
            onPageChange={onPageChange}
            sx={{ display: "flex", flexDirection: "column-reverse" }}
            ActionsComponent={PaginationAction}
          />
        )}
      </Box>
      <ModalBox
        open={filterOrder != null && filterOrder.length > 0}
        onCloseModal={() => toggleOrderInfoVisibility(null)}
        width="70%"
      >
        <ZohoOrderLifeCycle orderNumber={filterOrder} />
      </ModalBox>
    </Box>
  );
};

export default OrderListView;
