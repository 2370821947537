import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";

import {
  ButtonV1,
  Loader,
  PaginationAction,
  StatusChip,
  SvgIcon,
} from "components";
import { Filters } from "./components";
import ApproveModal from "./ApproveModal";
import HoverBlock from "./HoverBlock";

import { useToaster } from "hooks";
import {
  decimalRoundOff,
  downloadPdf,
  formatDate,
  moneyFormat,
  validateNull,
} from "utils";
import { getCall_v2, postCall_v2 } from "services";
import { DO_SHORTCLOSE_HEADER, DO_STATUS } from "../constant";
import { STATUS_CHIP, success, error } from "constants";

const rowsPerPage = 100;

const DoShortClose = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [doShortClosureList, setDoShortClosureList] = useState({});
  const navigation = useNavigate();
  const triggerToaster = useToaster();

  const navigateToOrderDetail = (customerOrderNumber) => {
    if (customerOrderNumber) {
      navigation(`/order-list/${customerOrderNumber}?tab=orderDetails`);
    }
  };

  const handleModal = (data) => {
    setModalData(data);
    setModalOpen(!isModalOpen);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchRequestData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filters]);

  const fetchRequestData = async () => {
    try {
      const { data } = await postCall_v2(
        `/joms/api/external/v1/plant-supply/fetch-do-shortclosure-list`,
        {
          offset: page * rowsPerPage,
          limit: rowsPerPage,
          filterDto: {
            customerGstin: filters?.customerGstin || null,
            orderNumber: filters?.orderNumber || null,
            status: filters?.status || null,
          },
        },
      );

      if (data) {
        setDoShortClosureList(data);
      }
    } catch (err) {
      triggerToaster(err?.data?.errorMessage || "Something went wrong!", error);
      console.error("Error fetching shipments:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStatusChange = async (status, data) => {
    const requestData = data ? data : modalData;
    const payload = {
      psIdentifier: requestData?.psIdentifier,
      lineItemId: requestData?.lineItemId,
      orderNumber: requestData?.orderNumber,
      status,
    };
    try {
      const res = await postCall_v2(
        `/oms/plant-supply/do/short-close/change-status`,
        payload,
      );
      if (res) {
        triggerToaster(res?.data ?? "Status changes successfully", success);
        fetchRequestData();
        setModalOpen(false);
      }
    } catch (err) {
      triggerToaster(
        err?.data?.title || `Error occurred, please try again`,
        error,
      );
    }
  };

  const handlePageChange = useCallback(
    (_event, pageNumber) => setPage(pageNumber),
    [],
  );

  useEffect(() => {
    fetchRequestData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilterSubmit = (filterData) => {
    setFilters(filterData);
  };

  const doShortCloseDTOList = doShortClosureList?.doShortCloseApprovalDTOList;

  const handleDownloadFile = async (row) => {
    const fileName = "attachment";
    try {
      const { data } = await getCall_v2(
        `/oms/plant-supply/view/do/short-close/file-by-id?id=${row?.id}`,
      );

      downloadPdf(data, fileName);
      triggerToaster(`Downloading ${fileName}`, success);
    } catch (err) {
      triggerToaster(`Unable to download ${fileName}`, error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <Box>
      <Filters onFilterSubmit={handleFilterSubmit} />
      <>
        <TableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {DO_SHORTCLOSE_HEADER.map((ele) => (
                  <TableCell>{ele}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colspan={DO_SHORTCLOSE_HEADER.length}>
                    <Loader sx={{ marginTop: 0 }} />
                  </TableCell>
                </TableRow>
              ) : !!doShortCloseDTOList?.length ? (
                doShortCloseDTOList?.map((request, index) => {
                  const lineItemKey = index;
                  return (
                    <TableRow key={lineItemKey}>
                      <TableCell width={100}>{request?.psIdentifier}</TableCell>
                      <TableCell width={100}>
                        <ButtonV1
                          textStyle={{ fontSize: 14 }}
                          title={request.orderNumber}
                          variant="text"
                          size="small"
                          onClick={() =>
                            navigateToOrderDetail(request.orderNumber)
                          }
                        />
                      </TableCell>
                      <TableCell>{request?.productInfo?.name}</TableCell>
                      <TableCell>
                        {decimalRoundOff(request?.releasedSkuQuantity, 3)}
                      </TableCell>
                      <TableCell>
                        {decimalRoundOff(request?.dispatchedSkuQuantity, 3)}
                      </TableCell>
                      <TableCell>
                        {decimalRoundOff(
                          request?.releasedSkuQuantity -
                            request?.dispatchedSkuQuantity,
                          3,
                        )}
                      </TableCell>
                      <TableCell>
                        {moneyFormat(request?.residualValue)}
                      </TableCell>
                      <TableCell>
                        {moneyFormat(request?.releasedValue)}
                      </TableCell>
                      <TableCell>
                        {validateNull(request?.sourceSellerName)}
                      </TableCell>
                      <TableCell>
                        {validateNull(request?.sellerGstin)}
                      </TableCell>
                      <TableCell>
                        {formatDate(request?.createdAt) ?? "-"}
                      </TableCell>
                      <TableCell>{validateNull(request?.createdBy)}</TableCell>
                      <TableCell>
                        {formatDate(request?.approvedAt) ?? "-"}
                      </TableCell>
                      <TableCell>{validateNull(request?.approvedBy)}</TableCell>
                      <TableCell>
                        <Box
                          style={{ textAlign: "center", cursor: "pointer" }}
                          onClick={() => handleDownloadFile(request)}
                        >
                          {request?.remarks ? (
                            <HoverBlock
                              LabelChildren={
                                <IconButton>
                                  <SvgIcon
                                    name={"chat-unread"}
                                    width={24}
                                    height={24}
                                  />
                                </IconButton>
                              }
                              data={{
                                Remarks: request?.remarks,
                              }}
                            />
                          ) : (
                            <IconButton>
                              <SvgIcon
                                name={"chat-read"}
                                width={24}
                                height={24}
                              />
                            </IconButton>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        {request?.status === "Requested" ? (
                          <Box display={"flex"} gap={2}>
                            <ButtonV1
                              size="small"
                              title={"Approve"}
                              onClick={() => handleModal(request)}
                            />
                            <ButtonV1
                              size="small"
                              title={"Reject"}
                              variant="outlined"
                              onClick={() =>
                                handleStatusChange(DO_STATUS.rejected, request)
                              }
                            />
                          </Box>
                        ) : (
                          <>
                            <StatusChip
                              label={
                                request?.status === DO_STATUS.approved
                                  ? "Approved"
                                  : "Rejected"
                              }
                              type={
                                request?.status === DO_STATUS.approved
                                  ? STATUS_CHIP.success
                                  : STATUS_CHIP.error
                              }
                            />
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colspan={DO_SHORTCLOSE_HEADER.length}>
                    <Typography textAlign={"center"}>No data found</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {!!doShortClosureList && (
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              rowsPerPage={rowsPerPage}
              page={Math.ceil(doShortClosureList?.offset / rowsPerPage) ?? 0}
              count={doShortClosureList?.totalCount ?? 0}
              onPageChange={handlePageChange}
              sx={{ display: "flex", flexDirection: "column-reverse" }}
              ActionsComponent={PaginationAction}
            />
          )}
        </TableContainer>
      </>
      <>
        <ApproveModal
          open={isModalOpen}
          handleModal={handleModal}
          modalData={modalData}
          handleStatusChange={handleStatusChange}
        />
      </>
    </Box>
  );
};

export default DoShortClose;
