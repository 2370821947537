import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import { styled } from "@mui/system";

import theme from "themeProvider";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    maxWidth: "none", // Prevents MUI's default width restriction
    color: theme.palette.common.black,
    boxShadow: `0 0 4px 2px ${theme.palette.grey[200]}`,
  },
}));

const TooltipLabel = ({ title, labelChildren, placement, customStyle }) => {
  return (
    <LightTooltip arrow title={title} placement={placement} sx={customStyle}>
      {labelChildren}
    </LightTooltip>
  );
};

TooltipLabel.propTypes = {
  placement: PropTypes.string,
  title: PropTypes.element.isRequired,
  labelChildren: PropTypes.element.isRequired,
};

export default TooltipLabel;
