import { useEffect, useState, useRef } from "react";
import { Box } from "@mui/material";

import { postCall_v2 } from "services";
import InvoiceRejectionListView from "./InvoiceRejectionListView";
import { UploadCSV } from "components";
import { useToaster } from "hooks";
import { downloadCsv } from "utils";
import { success, error } from "constants";

const UploadInvoiceRejection = () => {
  const [fileContent, setFileContent] = useState(null);

  const triggerToaster = useToaster();
  const csvUploadRef = useRef();
  const invHistoryRef = useRef();

  const fileUploadCallback = (contents) => {
    if (contents && contents.length) {
      setFileContent(contents);
    }
  };

  useEffect(() => {
    if (fileContent) {
      (async () => {
        try {
          const formData = new FormData();
          formData.append("file", csvUploadRef.current.getFile());
          const result = await postCall_v2(
            "/joms/api/external/v1/shipment/bulk-reject-invoice",
            formData,
          );
          downloadCsv(result?.data, "Invoice_rejection");
          triggerToaster(
            "Invoice details added to queue for rejection, pls check downloaded csv for results",
            success,
          );
        } catch (ex) {
          console.error("Exception occurred in invoice rejection process", ex);
          triggerToaster(
            "Invoice rejection process failed, pls try again",
            error,
          );
        } finally {
          csvUploadRef.current.resetFile();
          invHistoryRef.current.fetchInvRejectionHistory();
        }
      })();
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileContent]);

  return (
    <Box padding={3} px={8}>
      <Box mb={12}>
        <UploadCSV
          headerText={""}
          parsedFileCallbackFn={fileUploadCallback}
          ref={csvUploadRef}
        />
      </Box>

      <InvoiceRejectionListView ref={invHistoryRef} />
    </Box>
  );
};

export default UploadInvoiceRejection;
