import { useEffect } from "react";
import { Box, TableCell, TableRow, Typography, useTheme } from "@mui/material";

import { TableWrapper } from "components";
import { ColumnTitle } from "CustomStyledComponents";

import { useFetchData } from "hooks";
import { blockObjectHeader, blockType } from "./constants";
import { dateConvertor, moneyFormat, pascalCase, timeConvertor } from "utils";

const BlockObject = ({ orderNumber }) => {
  const {
    isLoading,
    refetch: fetchBlockObjectData,
    data: blockObjectData,
  } = useFetchData(
    "block-object",
    `/joms/api/external/v1/order/getBlockDetailsByOrderNumber?orderNumber=${orderNumber}`,
  );

  const theme = useTheme();

  useEffect(() => {
    fetchBlockObjectData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Typography
        mb={4}
        fontWeight={600}
        fontSize={20}
        color={theme.palette.grey["A300"]}
      >
        Block object
      </Typography>
      <TableWrapper
        tableHeader={blockObjectHeader.map((item) => {
          return (
            <TableCell key={item}>
              <ColumnTitle>{item}</ColumnTitle>
            </TableCell>
          );
        })}
        tableBody={
          blockObjectData?.data?.data ? (
            blockObjectData?.data?.data?.map((row, i) => {
              return (
                <TableRow>
                  <TableCell>
                    {`${dateConvertor(row?.createdAt)} ${timeConvertor(
                      row?.createdAt,
                    )}`}
                  </TableCell>
                  <TableCell>
                    {blockType[row?.blockType] ?? row?.blockType}
                  </TableCell>
                  <TableCell>{moneyFormat(row?.advanceAmount)}</TableCell>
                  <TableCell>{moneyFormat(row?.creditAmount)}</TableCell>
                  <TableCell>{pascalCase(row?.blockObjectStatus)}</TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableCell colSpan={5} align={"center"}>
              <Typography variant="p">
                No blocked amounts are available.
              </Typography>
            </TableCell>
          )
        }
      />
    </Box>
  );
};
export default BlockObject;
