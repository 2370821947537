import { useMemo } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

import { StyledTableRow } from "CustomStyledComponents";
import { moneyFormat, validateNull } from "utils";

const DeliveryReleaseHistory = ({ deliveryReleaseList }) => {
  const tableFields = useMemo(() => {
    return [
      {
        title: "Order number ",
        render: (ele) => (
          <Typography style={{ width: 120 }} fontSize={14}>
            {validateNull(ele?.ordNumber)}
          </Typography>
        ),
      },
      {
        title: "Cashback amount",
        render: (ele) => (
          <Typography style={{ width: 120 }} fontSize={14}>
            {moneyFormat(ele?.cashbackAmount)}
          </Typography>
        ),
      },
      {
        title: "Release status",
        render: (ele) => (
          <Typography fontSize={14}>{ele?.releaseStatus}</Typography>
        ),
      },
      {
        title: "Cashback status",
        render: (ele) => (
          <Typography style={{ width: 120 }} fontSize={14}>
            {validateNull(ele?.cashbackStatus)}
          </Typography>
        ),
      },
      {
        title: "Delivery release date",
        field: "zohoOrderDeliveryReleaseDate",
        render: (ele) => (
          <Typography fontSize={14}>
            {validateNull(ele?.zohoOrderDeliveryReleaseDate)}
          </Typography>
        ),
      },

      {
        title: "Is soft deleted",
        render: (ele) => (
          <Typography fontSize={14}>{ele?.isCancelled}</Typography>
        ),
      },
    ];
  }, []);

  return (
    <Box p={2}>
      <Box component={Paper}>
        <TableContainer
          sx={{
            minWidth: 700,
            maxHeight: "calc(100vh - 120px)",
          }}
        >
          <Table stickyHeader>
            <TableHead>
              {tableFields?.map((el) => (
                <TableCell key={el.field}>{el.title}</TableCell>
              ))}
            </TableHead>
            <TableBody>
              {!deliveryReleaseList?.length ? (
                <StyledTableRow>
                  <TableCell colSpan={tableFields.length} align="center">
                    <Typography textAlign={"center"}>No data found</Typography>
                  </TableCell>
                </StyledTableRow>
              ) : (
                deliveryReleaseList?.map((row, ind) => (
                  <StyledTableRow key={ind}>
                    {tableFields?.map((el) => (
                      <TableCell key={el?.field}>{el.render(row)}</TableCell>
                    ))}
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

DeliveryReleaseHistory.propTypes = {
  deliveryReleaseList: PropTypes.array,
};
export default DeliveryReleaseHistory;
