export const ORDER_BLOCK = Object.freeze({
  manualUpload: {
    orderId: "orderId",
    entityGstin: "entityGstin",
    date: "date",
    orderAmount: "orderAmount",
    advanceAmount: "advanceAmount",
    creditAmount: "creditAmount",
    invoiceComments: "invoiceComments",
    creditInstrument: "creditInstrument",
  },
  tableHead: [
    "Order ID",
    "Entity GSTIN",
    "Credit instrument",
    "Date",
    "Order amount",
    "Advance amount",
    "Credit amount",
    // "Action",
    "Comment",
  ],
  invoiceYup: "orderBlock",
  action: { edit: "Edit", delete: "Delete" },
});
